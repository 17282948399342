import { reportWarning } from '../common/errorReporting';
import { ITokenizeResult } from './types';

export interface ICheckoutErrors {
  [key: string]: Array<string>;
}

export interface ICheckoutErrorResult {
  errors: ICheckoutErrors;
}

export class CheckoutError extends Error {
  errorMessages: ICheckoutErrors;

  constructor(message, errorMessages?: ICheckoutErrors) {
    super(message);
    this.name = 'CheckoutError';
    this.errorMessages = errorMessages ? Object.assign({}, errorMessages) : {};
  }
}

export function createErrorFromJSON(data: ICheckoutErrorResult | ITokenizeResult): CheckoutError {
  let errorMessage = window.Tickit_Checkout_i18n.something_went_wrong;
  if (!data) {
    data = { errors: {} };
  }
  // const data = xhr.responsedata;

  if (data.errors && data.errors.base) {
    errorMessage = data.errors.base[0];
  }

  return new CheckoutError(errorMessage, data.errors);
}

export function createErrorFromXHR(xhr: JQuery.jqXHR): CheckoutError {
  const errorMessage = window.Tickit_Checkout_i18n.something_went_wrong;
  // let data: ICheckoutErrorResult = { errors: {} };

  try {
    const data: ICheckoutErrorResult = xhr.responseJSON;
    return createErrorFromJSON(data);
  } catch (e) {
    console.error(e);
    reportWarning('checkoutWithBackgroundProcessing.createErrorFromXHR', e);
  }

  return new CheckoutError(errorMessage);
}
