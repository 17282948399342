import 'lightgallery.js';
import { trackEvent } from './analytics';

const openGallery = (gallery, images) => {
  window.lightGallery(gallery, {
    thumbnail: true,
    fullScreen: false,
    download: false,
    counter: false,
    dynamic: true,
    dynamicEl: images,
  });
};

const initGallery = (gallery) => {
  $(gallery)
    .find('a')
    .click(function (event) {
      event.preventDefault();
      $.getJSON(
        gallery.dataset.url,
        {
          item_id: gallery.dataset.itemId,
          timestamp: gallery.dataset.timestamp,
        },
        (response) => {
          openGallery(gallery, response.images);
        }
      );

      trackEvent('cart', 'open-modal-gallery', gallery.dataset.url);
    });
};

export default function () {
  for (let gallery of document.querySelectorAll('.merch-gallery')) {
    initGallery(gallery);
  }
}
