import { trackEvent } from './analytics';

const COUNTDOWN_DANGER_THRESHOLD = 60;

let countdown: JQuery<HTMLElement>,
  countdownRemaining: number,
  countdownInterval: number,
  countdownHasDanger,
  expiryMessage: string,
  redirectOnExpiryURL: string;

export const setCountdownRemaining = (seconds) => {
  countdownRemaining = seconds;
};

const formatSeconds = function (time) {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  const paddedSeconds = seconds <= 9 ? `0${seconds}` : seconds.toString();

  return `${minutes.toString()}:${paddedSeconds}`;
};

export const cancelCountdown = function () {
  countdown.removeClass('danger').addClass('empty').text('');
  window.clearInterval(countdownInterval);
  countdownInterval = null;
  countdownHasDanger = false;
};

export const updateCountdown = function (): void {
  if (countdownRemaining == null) {
    cancelCountdown();
    return;
  }

  if (countdownRemaining > 0) {
    countdown.removeClass('empty').text(formatSeconds(countdownRemaining));
    if (countdownRemaining <= COUNTDOWN_DANGER_THRESHOLD && !countdownHasDanger) {
      countdown.addClass('danger');
      countdownHasDanger = true;
    } else if (countdownRemaining > COUNTDOWN_DANGER_THRESHOLD && countdownHasDanger) {
      // the countdown can be increased during checkout so we may need to reset the danger state
      countdown.removeClass('danger');
      countdownHasDanger = false;
    }

    if (countdownInterval) return;
    countdownInterval = window.setInterval(function () {
      countdownRemaining -= 1;
      updateCountdown();
    }, 1000);
  } else {
    cancelCountdown();

    // don't redirect if we're in the middle of a checkout form submission
    if (!window.TickitCheckoutSubmitting) {
      trackEvent('cart', 'expired');
      alert(expiryMessage);
      window.parent.location.href = redirectOnExpiryURL;
    }
  }
};

export default function (body: JQuery<HTMLBodyElement>, storeURL: string): void {
  const i18n = body.attr('data-i18n');
  expiryMessage = JSON.parse(i18n).cart_expired;
  countdown = body.find('.cart-countdown') as JQuery<HTMLElement>;
  redirectOnExpiryURL = storeURL;
}
