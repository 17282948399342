// TODO: https://developer.mozilla.org/en-US/docs/Web/API/Constraint_validation

import cardValidator from 'card-validator';
import { FormatDateOptions, formatCreditCard, formatDate } from 'cleave-zen';
import verifyEmailFormat from '../frontend/verifyEmailFormat';

const BOGUS_CARD_NUMBERS = [1, 2, 3];

let usingBogusGateway, acceptedCardTypes, cardIcons;

const cvvDetails = {
  name: 'CVD',
  size: 4, // default to four and narrow it down only when we know it's not an Amex
};

const CARD_TYPE_ICONS = {
  visa: 'visa',
  mastercard: 'mastercard',
  'american-express': 'american_express',
  'diners-club': 'diners',
  discover: 'discover',
  jcb: 'jcb',
  maestro: 'maestro',
};
const renderValidationStatus = function (field: JQuery, isValid: boolean, invalidMessage?: string) {
  const control = field.closest('.control');
  if (isValid) {
    control.addClass('valid').removeClass('invalid');
  } else {
    control.addClass('invalid').removeClass('valid');
  }

  if (invalidMessage) {
    console.debug('Passed `invalidMessage` but did not render', invalidMessage);
  }
};

const highlightCardIcon = function (cardType: string) {
  const slug = CARD_TYPE_ICONS[cardType];
  const current = cardIcons.filter(`.card-icon-${slug}`);

  if (current.length === 1) {
    cardIcons.removeClass('current').addClass('not-current');
    current.removeClass('not-current').addClass('current');
  } else {
    cardIcons.removeClass('current not-current');
  }
};

const validateCardType = function (field) {
  let invalidMessage;
  let isValid = true;
  const value = field.val();
  const result = cardValidator.number(value);
  const cardType = result.card ? result.card.type : null;
  highlightCardIcon(cardType);

  // store CVV name and size for later validation if the card brand is known
  if (result.isPotentiallyValid && result.card) {
    cvvDetails.name = result.card.code.name;
    cvvDetails.size = result.card.code.size;
  }

  if (usingBogusGateway && BOGUS_CARD_NUMBERS.includes(parseInt(value, 10))) {
    cvvDetails.size = 3; // allow 3 digit CVVs for bogus cards
    return [true, undefined];
  }

  if (!result.isValid) {
    return [false, window.Tickit_Checkout_i18n.card_number];
  }

  if (acceptedCardTypes && !acceptedCardTypes.includes(cardType)) {
    isValid = false;
    if (result.card && result.card.niceType) {
      invalidMessage = window.Tickit_Checkout_i18n.card_type.replace(
        '/%{cardName}/',
        result.card.niceType
      );
    } else {
      invalidMessage = window.Tickit_Checkout_i18n.card_number;
    }
  }

  return [isValid, invalidMessage];
};

const validateFieldByType = function (field) {
  let isValid = true;
  let invalidMessage = null;

  if (field.hasClass('card_number_input')) {
    [isValid, invalidMessage] = validateCardType(field);
  } else if (field.hasClass('card_expires_on_input')) {
    const result = cardValidator.expirationDate(field.val());
    if (!result.isValid) {
      isValid = false;
      invalidMessage = window.Tickit_Checkout_i18n.card_expires_on;
    }
  } else if (field.hasClass('card_verification_input')) {
    const result = cardValidator.cvv(field.val(), cvvDetails.size);
    if (!result.isValid) {
      isValid = false;
      invalidMessage = window.Tickit_Checkout_i18n.card_verification;
    }
  }

  return [isValid, invalidMessage];
};

const validateField = function (field) {
  let isValid = true;
  let invalidMessage;
  const value = field.val();
  if (value.length === 0) {
    isValid = false;
    invalidMessage = window.Tickit_Checkout_i18n.required_field;
  } else {
    [isValid, invalidMessage] = validateFieldByType(field);
  }

  renderValidationStatus(field, isValid, invalidMessage);
};

const bindCardFormatting = (form) => {
  const formatDateOptions: FormatDateOptions = { datePattern: ['m', 'Y'] };
  form.find('.card_number_input').on('input', (e) => {
    const value = e.target.value;
    e.target.value = formatCreditCard(value);
  });

  form.find('.card_expires_on_input').on('input', (e) => {
    const value = e.target.value;
    e.target.value = formatDate(value, formatDateOptions);
  });
};

export default function (form: JQuery) {
  cardIcons = form.find('.card-icon');
  const requiredFields = form.find('input[required], select');
  usingBogusGateway = form.hasClass('bogus');

  const jsonCardTypes = form.attr('data-card-types');

  if (jsonCardTypes) {
    try {
      acceptedCardTypes = JSON.parse(jsonCardTypes);
    } catch (error) {
      console.error(error);
    }
  }

  if (requiredFields.length) {
    requiredFields.on('blur change keyup', function () {
      validateField($(this));
    });
    bindCardFormatting(form);
  }

  const emailField = form.find('.control-group input[type=email]') as JQuery<HTMLInputElement>;
  emailField.on('change', function () {
    verifyEmailFormat($(this));
  });
}
