import $ from 'jquery';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import Loader from '../components/MultipackBuilder/Loader';
import { closeModal, openModal } from './publicModal';

const OPEN_MULTIPACK_BUILDER_EVENT_TYPE = 'tickit-open-multipack-builder';
const MODAL_ID = 'multipack-builder';
let builderOverlay: HTMLDivElement;
let root: Root;

const doc = $(document);

const loadMultipackBuilder = (props) => {
  if (!root) {
    builderOverlay = document.querySelector('#public-global-modal .pgm-contents');
    root = createRoot(builderOverlay);
  }

  doc.on('tickit:public-modal-close', function (event, data) {
    if (!data || data.id !== MODAL_ID) return;
    if (root) {
      root.unmount();
      root = undefined;
    }

    doc.off('tickit:public-modal-close');
  });

  root.render(
    <Loader
      multipackEndpoint={props.multipackEndpoint}
      handleClose={closeModal}
      updateEndpoint={props.updateEndpoint}
      quantity={props.quantity}
      itemID={props.itemID}
    />
  );
  openModal({ id: MODAL_ID, hideCloseButton: true });
};

const onOpenMultipackBuilder = function (event: CustomEvent) {
  event.preventDefault();

  const target: HTMLElement = event.detail.originalTarget;
  const quantity: number | undefined = event.detail.quantity || undefined;
  const container = target.closest('.select-tickets-row-multipack') as HTMLElement;

  const props = {
    multipackEndpoint: container.dataset.multipackEndpoint,
    updateEndpoint: container.dataset.updateEndpoint,
    itemID: container.dataset.item,
    quantity: quantity,
  };

  loadMultipackBuilder(props);
};

export const dispatchOpenMultipackBuilderEvent = (
  target: HTMLElement,
  quantity: number | undefined = undefined
) => {
  const newEvent = new CustomEvent(OPEN_MULTIPACK_BUILDER_EVENT_TYPE, {
    detail: { originalTarget: target, quantity: quantity },
    bubbles: true,
    cancelable: true,
  });
  document.dispatchEvent(newEvent);
};

function bindMultipackBuilderLinks(container?: HTMLElement) {
  if (!container) container = document.body;

  const rows = container.querySelectorAll('.select-tickets-row-multipack');
  rows.forEach((row) => {
    const btns = row.querySelectorAll('.btn-open-multipack-builder');

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function (originalEvent) {
        originalEvent.preventDefault();
        dispatchOpenMultipackBuilderEvent(originalEvent.target as HTMLElement);
      });
    }
  });
}

export default function init() {
  document.addEventListener(OPEN_MULTIPACK_BUILDER_EVENT_TYPE, onOpenMultipackBuilder);
  bindMultipackBuilderLinks();
}
