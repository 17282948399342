import { scrollToElement } from './utils';

export default function () {
  $('#terms-toggle').click(function (event) {
    const termsContent = $('#terms-content');

    if (!termsContent.length) return;

    event.preventDefault();
    termsContent.slideDown();
    scrollToElement(termsContent);
  });
}
