let body: JQuery<HTMLBodyElement> = undefined;

export const scrollToElement = (target: JQuery) => {
  try {
    if (!body) {
      body = $('body');
    }

    if (!target || target.length < 1) {
      console.warn('missing scrollToElement target');
      return;
    }

    const offset = body.hasClass('use-sticky-nav') ? 115 : 10;
    $('html, body').animate({ scrollTop: target.offset().top - offset }, 1000);
  } catch (error) {
    console.error(error);
  }
};
