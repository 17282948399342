import React from 'react';
import { reportError } from '../common/errorReporting';

class CheckoutErrorBoundary extends React.Component<
  { paymentIntentPath?: string; children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    console.error(error);
    reportError(error, { paymentIntentPath: this.props.paymentIntentPath });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      try {
        return <p>{window.Tickit_Checkout_i18n.something_went_wrong}</p>;
      } catch {
        return <p>Sorry, something went wrong.</p>;
      }
    }
    return this.props.children;
  }
}

export default CheckoutErrorBoundary;
