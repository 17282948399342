import React from 'react';

const AddingToCartImage = () => {
  return (
    <span className="adding-img">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none' }}
      >
        <circle
          cx="50"
          cy="50"
          opacity="0.5"
          fill="none"
          stroke="#000000"
          strokeWidth="4"
          r="46"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(18 50 50)"
          className="adding-img-circle"
        ></circle>
      </svg>
    </span>
  );
};

export default AddingToCartImage;
