// no jquery for this since we will be including it directly in both the admin and frontend
let submitButton, pendingQuestionsMessage;

export const BUTTON_DISABLED_SHAKE = 'button-disabled-shake';
export const UPDATE_EVENT = 'tickit.update-qa-form';

const updateSubmitButton = function() {
  Array.from(document.querySelectorAll('form.qa-section-form')).map(form => {
    return form.id;
  });

  // only enable the submit button when there are no forms showing
  if (document.querySelectorAll('form.qa-section-form').length) {
    submitButton.disabled = true;
    submitButton.dataset.disabled = 'true';
    pendingQuestionsMessage.hidden = false;
  } else {
    submitButton.disabled = false;
    submitButton.dataset.disabled = 'false';
    pendingQuestionsMessage.hidden = true;
  }
};

const updateControlGroup = function(parent) {
  const inputs = parent.querySelectorAll('input[type=radio], input[type=checkbox]');

  Array.from(inputs).forEach(input => {
    const controlGroup = input.closest('.question-control-with-details');
    if (input.checked) {
      controlGroup.classList.add('current');
      return;
    }

    controlGroup.classList.remove('current');
    Array.from(controlGroup.querySelectorAll('input[type=text]')).forEach(text => {
      text.value = '';
    });
  });

  updateSubmitButton();
};

const onDetailInputChange = event => {
  // TODO: verify 'closest' polyfill is loaded
  const parent = event.target.closest('.control-group');
  updateControlGroup(parent);
};

const bindInputs = function() {
  // Toggle the extra info inputs
  Array.from(document.querySelectorAll('.question-control-with-details input')).forEach(input => {
    if (input.dataset.bound) return;
    input.addEventListener('change', onDetailInputChange);
    input.dataset.bound = true;
  });
};

const handleUpdateEvent = function() {
  bindInputs();
  updateControlGroup(document.querySelector('.questions-form'));
};

export const unbind = function() {
  document.removeEventListener(UPDATE_EVENT, handleUpdateEvent);
};

const init = function() {
  const form = document.querySelector('.questions-form');
  if (!form) return;

  submitButton = document.querySelector('.btn-qa-form-done');
  pendingQuestionsMessage = document.querySelector('.pending-questions-message');

  bindInputs();
  updateSubmitButton();

  // we bind to the parent div since the button is disabled and unlickable(so we can add the
  // shake effect)
  document.querySelector('.checkout-button-control').addEventListener(
    'click',
    function(event) {
      if (submitButton.disabled) {
        event.preventDefault();
        event.stopPropagation();
        submitButton.classList.add(BUTTON_DISABLED_SHAKE);
        window.setTimeout(() => submitButton.classList.remove(BUTTON_DISABLED_SHAKE), 1000);
      }
    },
    false
  );

  document.addEventListener(UPDATE_EVENT, handleUpdateEvent, false);
};

export default function() {
  init();
}
