import React from 'react';

interface Props {
  min: number;
  max: number;
  value: number;
  disabled: boolean;
  onChange: (number) => void;
}

export const TicketListQuantity = (props: Props) => {
  const onChange = (event) => {
    props.onChange(+event.target.value);
  };

  const quantityOptions = [];

  for (let i = props.min; i <= props.max; i++) {
    quantityOptions.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  quantityOptions.unshift(
    <option value={0} key={0}>
      0
    </option>
  );

  return (
    <div className="seatmap-cart-select seatmap-cart-select-quantity">
      <select value={props.value} onChange={onChange} disabled={props.disabled}>
        {quantityOptions}
      </select>
    </div>
  );
};
