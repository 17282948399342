// TODO: unify this with 'expandables'

export const OPEN_CLASS = 'open-expando';

const toggleExpandos = clickedExpando => {
  for (let expando of Array.from(document.querySelectorAll('.expando'))) {
    if (expando === clickedExpando) {
      if (clickedExpando.className.indexOf(OPEN_CLASS) == -1) {
        clickedExpando.classList.add(OPEN_CLASS);
      } else {
        clickedExpando.classList.remove(OPEN_CLASS);
      }
    } else {
      expando.classList.remove(OPEN_CLASS);
    }
  }
};

const initExpando = function(expando) {
  if (expando.classList.contains('always-expando')) return;

  const header = expando.querySelector('.expando-header');
  if (!header) return;

  const headerTitle = document.createElement('div');
  headerTitle.className = 'expando-title';
  headerTitle.innerHTML = header.innerHTML;

  const HeaderIcon = document.createElement('div');
  HeaderIcon.className = 'expando-icon ti ti-thin-plus';

  header.innerHTML = '';
  header.append(headerTitle);
  header.append(HeaderIcon);
  header.style.cursor = 'pointer';

  header.addEventListener('click', () => toggleExpandos(expando));
};

export default function init() {
  for (let expando of Array.from(document.querySelectorAll('.expando'))) {
    initExpando(expando);
  }
}
