import debounce from 'lodash/debounce';

const MOBILE_BREAKPOINT = 680;
const STICKY_CLASS = 'nav-is-sticky';

export default function (body) {
  // check if we'll ever need sticky nav
  // based on the mobile breakpoint in the v4/globals stylesheet
  if (window.screen.width < MOBILE_BREAKPOINT || window.screen.height < MOBILE_BREAKPOINT) {
    return;
  }

  const win = $(window);
  const container = $('.store-nav-and-body');
  let offsetY = undefined;
  let currentlySticky = false;

  // wait for images to load
  for (let timeout of [500, 1000, 1500, 2500, 4000, 6000, 10000]) {
    window.setTimeout(() => updateAfterResize(), timeout);
  }

  const updateSticky = function () {
    const scrollTop = win.scrollTop();
    if (scrollTop > offsetY) {
      if (!currentlySticky) {
        body.addClass(STICKY_CLASS);
        currentlySticky = true;
      }
    } else {
      if (currentlySticky) {
        body.removeClass(STICKY_CLASS);
        currentlySticky = false;
      }
    }
  };

  const updateAfterResize = function () {
    offsetY = container.offset().top;
    updateSticky();
  };

  const lazyUpdate = debounce(updateAfterResize, 300, true);

  updateAfterResize();
  win.on('resize', lazyUpdate);
  win.on('scroll', updateSticky);
}
