import React from 'react';
import PropTypes from 'prop-types';

export const ZoomControls = (props) => {
  const handleZoomIn = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.handleZoomIn();
  };

  const handleZoomOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.handleZoomOut();
  };

  const renderControl = (label, onClick) => {
    return (
      <a key={label} className="control" onClick={onClick}>
        {label}
      </a>
    );
  };

  const controls = [renderControl('+', handleZoomIn), renderControl('-', handleZoomOut)];

  return <div className="seatmap-zoom-controls">{controls}</div>;
};

ZoomControls.propTypes = {
  handleZoomIn: PropTypes.func.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
};
