import { trackEvent } from './analytics';

// open/close logic for the tenatively sold out badges on the event pages
let currentMessage: JQuery<HTMLDivElement>;
let allMessages: JQuery;

const onClickWhileOpen = function (event) {
  const isTouchWithinContainer = currentMessage[0].contains(event.target);
  if (isTouchWithinContainer) return;
  closeAll();
};

const open = function () {
  closeAll();
  currentMessage.addClass('showing');
  document.body.addEventListener('click', onClickWhileOpen, false);
  trackEvent('tentatively-soldout-message', 'show');
};

const closeAll = function () {
  allMessages.removeClass('showing');
  document.body.removeEventListener('click', onClickWhileOpen, false);
};

export default function init() {
  const badges = $('.tentatively-soldout-badge[data-target]');
  if (badges.length < 1) return;
  allMessages = $('.tentatively-soldout-message');

  badges.on('click', function (event) {
    event.preventDefault();
    event.stopPropagation();

    const link = $(this) as JQuery<HTMLAnchorElement>;
    const target = link.attr('data-target');
    currentMessage = $(`#${target}`);
    if (currentMessage.hasClass('showing')) {
      closeAll();
      return;
    }
    open();
  });
}
