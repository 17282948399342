import { trackEvent } from './analytics';
import getCSRFToken from '../common/getCSRFToken';
const ENDPOINT = '/check_email';
export const HINT_CLASS = 'check-email-hint';

interface IResponse {
  email?: string;
}

export default function (field: JQuery<HTMLInputElement>) {
  const request = $.ajax({
    method: 'POST',
    dataType: 'JSON',
    data: { email: field.val() },
    url: ENDPOINT,
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      withCredentials: true,
    },
  });

  const controlGroup = field.closest('.control-group') as JQuery<HTMLElement>;

  request.then(function (result?: IResponse) {
    let hint = controlGroup.find(`.${HINT_CLASS}`);

    if (!result || !result.email) {
      if (hint.length >= 0) hint.remove();
      return;
    }

    if (hint.length < 1) {
      controlGroup.append(`<div class="${HINT_CLASS}"></div>`);
      hint = controlGroup.find(`.${HINT_CLASS}`);
    }

    trackEvent('checkout', 'suggest-email', field.val());

    hint = hint.text(`Did you mean ${result.email}?`);

    hint.click(function () {
      field.val(result.email);
      hint.remove();
    });
  });

  // used for testing
  return request;
}
