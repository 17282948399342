export default function getCSRFToken(form?: HTMLFormElement): string | null {
  // prefer per-form CSRF tokens if they're available
  if (form) {
    const formToken = form.querySelector('[name=authenticity_token]')?.getAttribute('value');

    if (formToken) {
      return formToken;
    }
  }

  // otherwise use page-wide CSRF token
  return document.querySelector('meta[name=csrf-token]')?.getAttribute('content');
}
