// TODO: unify this with 'expandos'

import { trackEvent } from './analytics';

export const OPEN_CLASS = 'expanded';

let triggers = null;

const expand = (expando) => {
  expando.toggleClass(OPEN_CLASS);
  expando.find('.expandable-hidden').stop().slideToggle();
};

const bindLinks = function () {
  triggers.click(function (event) {
    event.preventDefault();
    const link = $(this);
    link.blur(); // remove focused state
    expand(link.closest('.expandable'));
    const trackingLabel = link.attr('data-label');
    if (trackingLabel) {
      trackEvent('click-expandable-link', trackingLabel);
    }

    return false;
  });

  $('.expandable.auto-expand').each(function () {
    const expando = $(this);
    window.setTimeout(() => expand(expando), 2500);
  });
};

export default function init() {
  triggers = $('.expandable-trigger');
  if (!triggers.length) return;

  bindLinks();
}
