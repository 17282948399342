import { CheckoutError } from './checkoutErrors';
import { scrollToElement } from './utils';

export const INVALID_CLASS = 'validation-error-notice';
export const VALID_CLASS = 'validation-success-notice';
export const VALIDATION_NOTICE_SELECTOR = '#validation-notice';

let form: JQuery;
let validationNotice: JQuery;

function initializeValidationNotice() {
  validationNotice = form.find(VALIDATION_NOTICE_SELECTOR) as JQuery<HTMLElement>;
}

export const resetValidation = function () {
  initializeValidationNotice();

  validationNotice.attr('hidden', 'hidden');
  validationNotice.removeClass(INVALID_CLASS).addClass(VALID_CLASS);
  validationNotice.find('.text').empty();
  form.find('[data-field]').removeClass('invalid');
};

export const renderErrors = function (error: CheckoutError) {
  initializeValidationNotice();
  const errors = Object.assign({}, error.errorMessages);
  const notices: Array<string> = [];
  if (!errors) {
    validationNotice.attr('hidden', 'hidden');
    return;
  }

  // render base errors first
  if (errors.base) {
    errors.base.forEach((error) => notices.push(error));
    delete errors.base;
  }

  for (const key in errors) {
    const keyErrors = errors[key];
    form.find(`[data-field='${key}']`).addClass('invalid');
    keyErrors.forEach((error) => notices.push(error));
  }

  if (notices.length === 0) {
    notices.push(error.message);
  }

  if (notices.length === 0) {
    resetValidation();
    return;
  }

  validationNotice.find('.text').html(`<p>${notices.join('<br>')}</p>`);
  validationNotice.removeClass(VALID_CLASS).addClass(INVALID_CLASS).removeAttr('hidden');

  window.setTimeout(function () {
    scrollToElement(validationNotice);
  }, 750);
};

export default function ($form: JQuery) {
  form = $form;
}
