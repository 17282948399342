/*
  Handle the main checkout form using Rails' unobtrusive javascript (UJS) -- <form data-remote>

  This is the simplest checkout flow -- all we do here is disable some elements during submission
  and either redirect on success or render errors and re-enable the form.

  The UJS flow is currently only use for the transfer resale acceptance checkout.
*/

import { createErrorFromXHR } from './checkoutErrors';
import checkoutValidationErrors, {
  renderErrors,
  resetValidation,
} from './checkoutValidationErrors';
import { ICheckoutResult } from './types';

const bindRemoteSubmission = (form: JQuery) => {
  // JSON actions will only be used if form[remote] is set - currently that's just
  // the accept resale / purchase form
  let isRedirecting = false;

  // allow submitting with missing fields, see:
  // https://github.com/rails/jquery-ujs/issues/319
  form.on('ajax:aborted:required', () => false);

  form.on('ajax:before', () => {
    isRedirecting = false;
    form.addClass('submitting');
    resetValidation();
  });

  form.on('ajax:success', function (_xhr, response: ICheckoutResult) {
    isRedirecting = true;
    window.location.assign(response.redirect_url);
  });

  form.on('ajax:error', function (event: JQuery.TriggeredEvent, xhr: JQueryXHR) {
    form.removeClass('submitting');
    const error = createErrorFromXHR(xhr);
    renderErrors(error);
  });

  // this usually fires before the redirect begins and un-disables the submit
  // button which is kind of confusing -- we add the redirecting class to hide
  // the button and may in future show a redirecting or success UI element
  form.on('ajax:complete', () => {
    if (isRedirecting) {
      form.addClass('redirecting');
    }
  });
};

export default function (form) {
  checkoutValidationErrors(form);
  bindRemoteSubmission(form);
}
