import React from 'react';
import { addSVGClass, removeSVGClass } from '../svg-class';
import { SeatTooltip } from './SeatTooltip';
import SeatmapMap from './SeatmapMap';
import {
  handleResetZoom,
  handleZoomIn,
  handleZoomOut,
  initSeatmapInteraction,
} from './interaction';
import { PossibleItems, SeatData } from './types';
import { ZoomControls } from './zoom_controls';

const SEAT_CLASS = 'seatmap-seat';
const ACTIVE_CLASS = 'seatmap-seat-active';

interface Props {
  allowSelection: boolean;
  handleAddSeat: (itemId: string, qid: string) => void;
  handleMapReady: () => void;
  handleRemoveSeat: (qid: string) => void;
  handleResetZoom: () => void;
  onSelectQID: (qid: string | undefined) => void;
  hideTooltips?: boolean;
  possibleItems: PossibleItems;
  seats: { [key: string]: SeatData };
  path: string;
}

interface State {
  selectedSeat: SVGCircleElement | null;
  selectedSeatData: SeatData | null;
  currentTransform: null;
}

const INITIAL_STATE: State = { selectedSeat: null, selectedSeatData: null, currentTransform: null };

export default class PublicSeatmapMapContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleAddSeat = this.handleAddSeat.bind(this);
    this.handleRemoveSeat = this.handleRemoveSeat.bind(this);
    this.handleMapReady = this.handleMapReady.bind(this);
    this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = Object.assign({}, INITIAL_STATE);
  }

  reset() {
    if (this.state.selectedSeat) {
      removeSVGClass(this.state.selectedSeat, ACTIVE_CLASS);
    }
    this.setState(INITIAL_STATE);
    this.props.onSelectQID(undefined);
  }

  handleAddSeat(itemId: string) {
    // TODO: handle multiple item types
    const qid = this.state.selectedSeat.getAttribute('data-qid');
    this.props.handleAddSeat(itemId, qid);
    this.reset();
  }

  handleRemoveSeat() {
    const qid = this.state.selectedSeat.getAttribute('data-qid');
    this.props.handleRemoveSeat(qid);
    this.reset();
  }

  handleMapReady() {
    initSeatmapInteraction('.seatmap-map-container', this.onZoom);
    this.props.handleMapReady();
  }

  resetView() {
    handleResetZoom();
  }

  onZoom(currentTransform) {
    this.setState({ currentTransform: currentTransform });
  }

  // use React's built-in event handler on the parent div
  onClick(event) {
    const { target } = event;

    this.reset();

    // already open for the current seat so close it
    if (target === this.state.selectedSeat) return;
    if (!target.className.baseVal || target.className.baseVal.indexOf(SEAT_CLASS) === -1) return;

    const qid = target.getAttribute('data-qid'),
      seatData = this.props.seats[qid];

    addSVGClass(target, ACTIVE_CLASS);

    this.setState({
      selectedSeat: target,
      selectedSeatData: seatData,
    });

    this.props.onSelectQID(qid);
  }

  handleCloseTooltip() {
    if (this.state.selectedSeat) {
      removeSVGClass(this.state.selectedSeat, ACTIVE_CLASS);
    }

    this.setState({ selectedSeat: null, selectedSeatData: null });
    this.props.onSelectQID(undefined);
  }

  renderTooltip() {
    if (this.props.hideTooltips) return null;
    return (
      <SeatTooltip
        seat={this.state.selectedSeat}
        seatData={this.state.selectedSeatData}
        currentTransform={this.state.currentTransform}
        allowSelection={this.props.allowSelection}
        possibleItems={this.props.possibleItems}
        handleAddSeat={this.handleAddSeat}
        handleRemoveSeat={this.handleRemoveSeat}
        handleClose={this.handleCloseTooltip}
      />
    );
  }

  render() {
    // passing the currentTransform to the tooltip allows us to automatically reposition it
    // after every zoom event
    return (
      <div className="seatmap-map-container" onClick={this.onClick}>
        <SeatmapMap path={this.props.path} handleMapReady={this.handleMapReady} />
        <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
        {this.renderTooltip()}
      </div>
    );
  }
}
