import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

interface Props {
  confirmSetupIntentPath: string;
}

const controlKlass = (errors: string | null) => {
  return errors ? 'control invalid' : 'control';
};

const SetupForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setSubmitting(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: props.confirmSetupIntentPath,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setSubmitting(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const enableSubmit = !!stripe && !submitting;
  return (
    <form onSubmit={handleSubmit}>
      <div className="control-group">
        <div className={controlKlass(errorMessage)}>
          <PaymentElement
            options={{
              terms: { card: 'never' },
              fields: {
                billingDetails: {
                  name: 'auto',
                  email: 'auto',
                },
              },
            }}
          />
          {errorMessage && (
            <div className="card-errors" role="alert">
              {errorMessage}
            </div>
          )}
        </div>
      </div>

      <div className="checkout-button-control" hidden={!stripe || !elements}>
        <button className="btn-lg" type="submit" disabled={!enableSubmit}>
          {submitting
            ? window.Tickit_Checkout_i18n.buy_button_submitting
            : window.Tickit_Checkout_i18n.rpp_save_button}
        </button>
      </div>
    </form>
  );
};

export default SetupForm;
