import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { reportError, reportWarning } from '../../common/errorReporting';
import fetchPaymentIntent, {
  NoOrderForPaymentIntent,
  PaymentIntentAlreadyCaptured,
  ZeroDollarPaymentIntent,
} from '../../frontend/fetchPaymentIntent';
import { ICardData, IPretokenizedCardData } from '../../frontend/types';
import validateCheckoutBeforeConfirming from '../../frontend/validateCheckoutBeforeConfirming';
import startCartPaying from '../../frontend/startCartPaying';
import { stripeElementsAppearance } from '../StripeElementsTheme';
import Waiting from '../Waiting';
import CheckoutErrors from './CheckoutErrors';
import PaymentElementForm from './PaymentElementForm';

interface Props {
  stripePromise: Promise<Stripe>;
  checkoutParams: ICardData;
  paymentIntentPath: string;
  payingPath: string;
  validatePath: string;
  paymentPlanID: number | undefined;
  optinMode: 'automatic_optin' | 'default_optin' | 'default_optout';
  onPaymentIntentSucceeded: (
    paymentIntentID: string,
    checkoutJobID: string,
    cardData: IPretokenizedCardData,
  ) => void;
  openSans: string;
}

const StripeCheckoutFormContainer: React.FC<Props> = (props) => {
  const [paymentIntentSecret, setPaymentIntentSecret] = useState<string | undefined>(undefined);
  const [checkoutJobID, setCheckoutJobID] = useState<string | undefined>(undefined);
  const [setupError, setSetupError] = useState<string | undefined>(undefined);

  // Stripe checkout can take some time and some of the UI it displays seems to block us
  // from redirecting on an expired cart.  To prevent allowing payment intents to "confirm" when
  // a cart may already have expired we check the cart expiry immediately before confirming the payment.
  const validateCheckout = async (cardData: IPretokenizedCardData) => {
    startCartPaying(props.payingPath);
    return validateCheckoutBeforeConfirming(props.validatePath, cardData);
  };

  const { paymentIntentPath, onPaymentIntentSucceeded } = props;

  useEffect(() => {
    if (!paymentIntentSecret) {
      fetchPaymentIntent(paymentIntentPath)
        .then((paymentSource) => {
          console.log('fetchPaymentIntent', paymentSource);
          setPaymentIntentSecret(paymentSource.client_secret);
          setCheckoutJobID(paymentSource.checkout_job_id);
          setSetupError(undefined);
        })
        .catch((err) => {
          if (err instanceof PaymentIntentAlreadyCaptured) {
            const checkoutJobID = err.checkout_job_id;
            const paymentIntentID = err.payment_intent_id;
            reportWarning(err, {
              message: 'PaymentIntentAlreadyCaptured',
              paymentIntentPath: paymentIntentPath,
              paymentIntentID,
              checkoutJobID,
            });

            console.log('trying to tcall onPaymentIntentSucceeded with paymentIntentID', {
              checkoutJobID,
              paymentIntentID,
            });

            onPaymentIntentSucceeded(paymentIntentID, checkoutJobID, {});
            return;
          }

          if (err instanceof ZeroDollarPaymentIntent || err instanceof NoOrderForPaymentIntent) {
            reportWarning(err, {
              paymentIntentPath: paymentIntentPath,
            });
            return;
          }

          reportError(err, {
            message: 'Error fetching payment intent',
            paymentIntentPath: paymentIntentPath,
          });

          setSetupError(err.toString());
        });
    }
  }, [paymentIntentSecret, paymentIntentPath, onPaymentIntentSucceeded, checkoutJobID]);

  const options: StripeElementsOptions = {
    fonts: [{ family: 'Open Sans', src: props.openSans, weight: '300' }],
    clientSecret: paymentIntentSecret,
    appearance: stripeElementsAppearance(window.Tickit_Checkout_i18n.primary_color),
  };

  if (!paymentIntentSecret) {
    return <Waiting message="..." />;
  }

  if (setupError) {
    return <CheckoutErrors generalError={setupError} />;
  }
  return (
    <Elements stripe={props.stripePromise} options={options}>
      <PaymentElementForm
        onPaymentIntentSucceeded={onPaymentIntentSucceeded}
        validateCheckout={validateCheckout}
        checkoutJobID={checkoutJobID}
        paymentPlanID={props.paymentPlanID}
        checkoutParams={props.checkoutParams}
        optinMode={props.optinMode}
      />
    </Elements>
  );
};

export default StripeCheckoutFormContainer;
