const TOOLTIP_W = 380;

let svgRoot, svgMap, svgPoint;

export default function getCoordinatesForSeat(seat) {
  // only needs to be done once
  if (!svgPoint) {
    svgRoot = seat.ownerSVGElement;
    svgMap = svgRoot.querySelector('.seatmap-map');
    svgPoint = svgRoot.createSVGPoint();
  }

  svgPoint.x = +seat.getAttribute('cx');
  svgPoint.y = +seat.getAttribute('cy');

  const converted = svgPoint.matrixTransform(svgMap.getCTM());
  converted.x -= TOOLTIP_W / 2;

  return converted;
}
