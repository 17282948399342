// setup the event handlers for closing the page when in an iframe
export const CLOSE_IFRAME_POST_MESSAGE = 'tickit.close';

const sendCloseMessage = () => window.parent.postMessage(CLOSE_IFRAME_POST_MESSAGE, '*');

export const bindHandlers = function () {
  $('.iframe-close').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    sendCloseMessage();
  });

  $('#page').click(function (event) {
    if (event.target.id !== 'page') return;
    event.preventDefault();
    event.stopPropagation();
    sendCloseMessage();
  });
};

export default function init() {
  const inFrame = window !== window.parent;

  if (!inFrame) return;
  bindHandlers();
}
