import allCookies from '../common/allCookies';

// logic to display a warning/confirm prompt before allowing a user to add items to their
// cart -- added as part of COVID regs for Tulips of the Valley in April, 2021 and
// not currently used anywhere else

const PRE_ADD_WARNING_COOKIE = 'showed-preadd';

let needed = false;

function confirmPreAddWarning() {
  if (!window.Tickit_Checkout_i18n) return true;
  const confirmed = confirm(window.Tickit_Checkout_i18n.pre_add_to_cart_warning);

  if (confirmed) {
    allCookies.setItem(PRE_ADD_WARNING_COOKIE, '1', null, null, null, true);
    // Cookies.set(PRE_ADD_WARNING_COOKIE, 'shown', { secure: true });
    return true;
  } else {
    return false;
  }
}

function needToShowPreAddWarning() {
  if (!window.Tickit_Checkout_i18n) return false;
  if (window.Tickit_Checkout_i18n.pre_add_to_cart_warning?.length > 0) {
    const alreadyShown = allCookies.hasItem(PRE_ADD_WARNING_COOKIE);
    if (alreadyShown) {
      needed = false;
    } else {
      needed = true;
    }
  }

  return needed;
}

export { needToShowPreAddWarning, confirmPreAddWarning };
