// setup the nav for the knowledge-base pages

export const VISIBLE = 'kb-option-visible';
export const CURRENT_LINK = 'btn-reversed-active';

export default function init() {
  const links = $('.kb-option-links a');
  if (!links.length) return;

  links.click(function (event) {
    event.preventDefault();
    const link = $(this);
    const block = link.closest('.kb-option-block');
    const blockLinks = block.find('.kb-option-links a');
    blockLinks.removeClass(CURRENT_LINK);
    link.addClass(CURRENT_LINK);

    const options = block.find('.kb-option');
    options.removeClass(VISIBLE);
    $(link.attr('href')).addClass(VISIBLE);
  });
}
