import React from 'react';
interface Props {
  children?: React.ReactNode;
  titleLabel: string;
}
const Busy: React.FC<Props> = (props) => {
  return (
    <>
      <header className="multipack-builder-header">
        <h2 className="pgm-header-title">{props.titleLabel}</h2>
      </header>
      <article className='pgm-busy'>{props.children}</article>
    </>
  );
};

export default Busy;
