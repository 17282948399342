import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import UpdateStripeRemoteProfileForm from '../components/UpdateStripeRemoteProfileForm';

function init(form: JQuery<HTMLFormElement>) {
  const publishableKey = form.attr('data-stripe-publishable-key');
  const stripeAccountID = form.attr('data-stripe-account-id'); // account ID of the CONNECTED (ticket seller) stripe account
  const setupIntentPath = form.attr('data-setup-intent-path');
  const confirmSetupIntentPath = form.attr('data-confirm-setup-intent-path');
  const openSans = form.attr('data-open-sans-font');

  const stripePromise = loadStripe(publishableKey, {
    stripeAccount: stripeAccountID,
    apiVersion: '2023-10-16',
  });
  const root = createRoot(form[0]);
  root.render(
    <UpdateStripeRemoteProfileForm
      setupIntentPath={setupIntentPath}
      confirmSetupIntentPath={confirmSetupIntentPath}
      stripePromise={stripePromise}
      openSans={openSans}
    />
  );
  return;
}

export default function () {
  const form = $('.remote-payment-profile-form.stripe') as JQuery<HTMLFormElement>;
  if (!form.length) return;
  init(form);
}
