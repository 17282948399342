import classNames from 'classnames';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import { CartData } from '../../frontend/types';
import formatCurrency from '../formatCurrency';
import { postData } from './api';
import QuantitySelector from '../QuantitySelector';
import { FreeformPresets } from './types';

export interface PwycSelectorProps {
  freeformPresets: FreeformPresets;
  itemId: string;
  itemTitle: string;
  basePrice: number;
  freeformBasePrice: number | null;
  quantity: number;
  maxQuantity: number;
  cart?: CartData;
  updateEndpoint: string;
  handleClose: () => void;
  onUpdate?: () => void;
}

const PwycSelector: React.FC<PwycSelectorProps> = (props) => {
  const { freeformPresets, freeformBasePrice, basePrice, quantity, maxQuantity, itemId } = props;
  const freeformAmounts = useMemo(() => Object.values(freeformPresets), [freeformPresets]);

  const [currentPrice, setCurrentPrice] = useState(
    freeformBasePrice ||
      freeformAmounts.find((option) => option.default)?.amount ||
      freeformAmounts[0]?.amount,
  );

  const matchingOption = freeformAmounts.findIndex((option) => option.amount === currentPrice);
  const [currentOption, setCurrentOption] = useState(matchingOption >= 0 ? matchingOption : null);

  const [lineItemQuantity, setLineItemQuantity] = useState(quantity);
  const priceSatisfied = currentPrice && currentPrice >= basePrice;

  const handleSelectOption = (index: number | null) => {
    setCurrentOption(index);
    if (index !== null) {
      setCurrentPrice(freeformAmounts[index].amount);
    }
  };

  const renderOptions = () => {
    const options = freeformAmounts.map((option, index) => {
      const classes = classNames({
        'pgm-radio-option': true,
        selected: currentOption === index,
      });
      return (
        <div className={classes} key={index}>
          <label>
            <span className="control">
              <input
                type="radio"
                name="price_selector"
                id={`price_selector_${index}`}
                value={option.amount}
                checked={currentOption === index}
                onChange={() => handleSelectOption(index)}
              />
            </span>
            <span className="desc">{formatCurrency(option.amount, true)}</span>
          </label>
        </div>
      );
    });

    options.push(
      <div className="pgm-radio-option">
        <label>
          <span className="control">
            <input
              type="radio"
              name="price_selector"
              id="price_selector_other"
              value="other"
              checked={currentOption === null}
              onChange={() => handleSelectOption(null)}
            />
          </span>
          <span className="desc">{window.Tickit_Checkout_i18n.select_pwyc_other}</span>
        </label>
      </div>,
    );

    return <div className="form-wrapper donation-presets">{options}</div>;
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value.replace(/[^\d.]/g, ''));
    setCurrentPrice(value);
    const matchingOption = freeformAmounts.findIndex((option) => option.amount === value);
    setCurrentOption(matchingOption >= 0 ? matchingOption : null);
  };

  const renderInput = () => {
    return (
      <div className="form-wrapper donation-freeform-price">
        <input
          type="number"
          name="items[893][freeform_base_price]"
          id="freeform-price-input"
          value={currentPrice}
          step="0.01"
          min={formatCurrency(basePrice, false)}
          required={true}
          onChange={handleInput}
        />
        {basePrice > 0 && (
          <small>
            {window.Tickit_Checkout_i18n.select_pwyc_minimum_amount}{' '}
            {formatCurrency(basePrice, true)}
          </small>
        )}
      </div>
    );
  };

  const handleSubmit: MouseEventHandler = async (event) => {
    event.preventDefault();

    try {
      await postData(props.updateEndpoint, itemId, lineItemQuantity, currentPrice);
      if (props.onUpdate) props.onUpdate();
      props.handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <header className="pwyc-selector-header">
        <h3 className="pgm-header-title">{window.Tickit_Checkout_i18n.select_pwyc_title}</h3>
        <div className="pwyc-selector-quantity-selector">
          <QuantitySelector
            currentQuantity={lineItemQuantity}
            maxQuantity={maxQuantity}
            setLineItemQuantity={setLineItemQuantity}
          />
        </div>
      </header>
      <article>
        <h4>{props.itemTitle}</h4>
        {renderOptions()}
        {renderInput()}
      </article>
      <footer>
        <div className="pgm-action-links">
          <button
            className={classNames({
              'btn-sm': true,
              'pgm-primary-action-link': true,
              'btn-primary': priceSatisfied,
            })}
            disabled={!priceSatisfied}
            onClick={handleSubmit}
          >
            {window.Tickit_Checkout_i18n.select_pwyc_update}
          </button>
          <button className="btn-sm btn-outline pgm-close-trigger" onClick={props.handleClose}>
            {window.Tickit_Checkout_i18n.cancel}
          </button>
        </div>
      </footer>
    </>
  );
};

export default PwycSelector;
