// we add these polyfills specifically rather than core-js/stable to keep file sizes as low as possible
import 'core-js/es/array/from';
import 'core-js/es/array/for-each';
import 'core-js/es/array/map';
import 'core-js/es/array/sort';
import 'core-js/es/array/includes';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/symbol';
import 'core-js/es/set';
import 'core-js/es/map';

import 'custom-event-polyfill';
import 'classlist-polyfill';
import elementDatasetPolyfill from './dataset-polyfill';

elementDatasetPolyfill();

const IOS_REGEX = /iPad|iPhone|iPod/i;

if (window.Modernizr) {
  window.Modernizr.addTest('ios', function () {
    return navigator.userAgent.match(IOS_REGEX);
  });
}
