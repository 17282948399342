import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { TicketListQuantity } from './quantity';

export class TicketListAddItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);

    const itemId = this.initialItemId(this.props);
    const quantity = this.initialQuantity(this.props, itemId);

    this.state = { itemId: itemId, quantity: quantity, disabled: false };
  }

  initialItemId(props) {
    const itemIds = Object.keys(props.items);
    if (this.state && itemIds.indexOf(this.state.itemId) > -1) return this.state.itemId;
    return itemIds.length === 1 ? itemIds[0] : -1;
  }

  initialQuantity(props, itemId) {
    const max = itemId > -1 ? props.items[itemId]?.max : 16;
    return Math.min(2, max || 16);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.busy) {
      // don't change the quantities until we've got a server response
      this.setState({ disabled: true });
      return;
    }

    const itemId = this.initialItemId(nextProps);
    const quantity = this.initialQuantity(nextProps, itemId);
    this.setState({ disabled: false, itemId: itemId, quantity: quantity });
  }

  handleItemChange(event) {
    this.setState({ itemId: event.target.value });
  }

  handleQuantityChange(quantity) {
    this.setState({ quantity: quantity });
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.handleUpdateItem(this.state.itemId, this.state.quantity);
  };

  renderControls() {
    const itemSelected = this.state.itemId > -1,
      disableInteraction = this.state.disabled || !itemSelected;

    const min = this.state.itemId > -1 ? this.props.items[this.state.itemId].min : 1;
    const max = this.state.itemId > -1 ? this.props.items[this.state.itemId].max : 16;
    const selectQuantity = Math.min(this.state.quantity, max);

    if (max < 1) {
      return <div className="soldout">Sold out</div>;
    }

    return (
      <div>
        <TicketListQuantity
          min={min}
          max={max}
          value={selectQuantity}
          onChange={this.handleQuantityChange}
          disabled={this.state.disable}
        />
        <button type="submit" disabled={disableInteraction} className="seatmap-cart-button">
          Add
        </button>
      </div>
    );
  }

  render() {
    if (Object.keys(this.props.items).length < 1) return false;

    const itemOptions = map(this.props.items, (ticketType, itemId) => {
      return (
        <option key={itemId} value={itemId}>
          {ticketType.title}
        </option>
      );
    });

    if (itemOptions.length > 1) {
      itemOptions.unshift(
        <option key={0} value={-1}>
          Select ticket type
        </option>
      );
    }

    return (
      <div className="seatmap-ticket-type">
        <form onSubmit={this.onSubmit} className="form">
          <div className="seatmap-cart-select seatmap-cart-select-type">
            <select
              value={this.state.itemId}
              onChange={this.handleItemChange}
              disabled={this.state.disabled}
            >
              {itemOptions}
            </select>
          </div>
          {this.renderControls()}
        </form>
      </div>
    );
  }
}

TicketListAddItem.propTypes = {
  items: PropTypes.object.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  busy: PropTypes.bool,
};
