import { trackEvent } from './analytics';

export const LINK_CLASS = 'download-single-ticket-pdf';

export default function init() {
  $(`.${LINK_CLASS}`).on('click', function () {
    const link = $(this).attr('href');

    try {
      trackEvent('single-ticket', 'download-single-ticket-pdf', link);
    } catch (err) {
      console.error('analytics-error', err);
    }
  });
}
