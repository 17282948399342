import React from 'react';
import classNames from 'classnames';
interface Props {
  itemID: string;
  title: string;
  selected: boolean;
  disabled?: boolean;
  quantityAvailable: number;
  soldout: boolean;
  onClick: (itemID: string) => void;
}
export default function ComboComponent(props: Props) {
  const onClick = (event) => {
    event.preventDefault();
    if (props.disabled) return;
    props.onClick(props.itemID);
  };

  let soldoutNotice;

  if (props.soldout) {
    if (props.quantityAvailable < 1) {
      soldoutNotice = (
        <span className="badge soldout-notice">{window.Tickit_Checkout_i18n.sold_out}</span>
      );
    } else {
      soldoutNotice = (
        <span className="badge soldout-notice">
          {props.quantityAvailable} {window.Tickit_Checkout_i18n.limited_quantity_available}
        </span>
      );
    }
  }

  return (
    <div
      onClick={onClick}
      className={classNames({
        'pgm-radio-option': true,
        'multipack-builder-component': true,
        selected: props.selected,
        disabled: props.disabled,
        soldout: props.soldout,
      })}
    >
      <label>
        <span className="control">
          <input type="radio" checked={props.selected} readOnly disabled={props.soldout} />{' '}
        </span>
        <span className="desc">
          {props.title}
          {soldoutNotice}
        </span>
      </label>
    </div>
  );
}
