import React from 'react';
import StyledSelect, { Option } from './StyledSelect';
interface Props {
  currentQuantity: number;
  maxQuantity: number;
  setLineItemQuantity: (quantity: number) => void;
}

export default function QuantitySelector(props: Props) {
  const { currentQuantity, maxQuantity, setLineItemQuantity } = props;
  const options: Option[] = Array(maxQuantity)
    .fill(1)
    .map((_, index) => {
      const value = (index + 1).toString();
      const label = `${value}`;
      return { value, label };
    });

  return (
    <StyledSelect
      name="line_item_quantity"
      value={currentQuantity.toString()}
      onChange={(val) => setLineItemQuantity(parseInt(val))}
      options={options}
      locale="en"
    />
  );
}
