import classNames from 'classnames';
import React from 'react';

const InCartProceedImage: React.FC<{
  updating: boolean;
  enabled: boolean;
}> = (props) => {
  if (!props.enabled) return null;

  const classes = classNames({ 'proceed-img': true, highlight: props.updating });

  return (
    <button className="img-button" type="submit">
      <span className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
        >
          <g className="proceed-img-group">
            <circle className="proceed-img-bg" fill="#FFFFFF" cx="12" cy="12" r="12"></circle>
            <polygon
              className="proceed-img-arrow"
              points="18.2,8.3 17.2,7.3 10.5,14 7.5,11 6.6,12 10.5,16 "
            ></polygon>
            <path
              className="proceed-img-circle"
              opacity="0.25"
              enableBackground="new"
              d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M12,1C5.9,1,1,5.9,1,12s5,11,11,11 s11-5,11-11S18.1,1,12,1z"
            ></path>
          </g>
        </svg>
      </span>
    </button>
  );
};

export default InCartProceedImage;
