import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import AddingToCartImage from './AddingToCartImage';
import InCartProceedImage from './InCartProceedImage';
import StyledSelect, { Option } from '../StyledSelect';
import { IItem } from './types';
import { Locale } from '../../frontend/types';

interface IProps {
  item: IItem;
  onUpdateCart: (itemID: string, quantity: number) => void;
  busy: boolean;
  quantity: number | undefined;
  locale: Locale;
}

const Item: React.FC<IProps> = (props) => {
  const [updating, setUpdating] = useState(false);
  const {
    id,
    title,
    price,
    description,
    currency,
    occurrence_description,
    min,
    max,
    soldout,
    previewing,
  } = props.item;
  const quantity = props.quantity || 0;

  useEffect(() => {
    setUpdating(false);
  }, [quantity, min, max, soldout, previewing]);

  const onChange = (quantity: any) => {
    setUpdating(true);
    let parsedQuantity = parseInt(quantity);
    if (isNaN(parsedQuantity)) {
      parsedQuantity = 0;
    }
    props.onUpdateCart(id, parsedQuantity);
  };

  const renderQuantitySelector = () => {
    if (props.item.previewing) {
      return (
        <div className="soldout-notice">
          <p className="ordertable-ticket-price">Upcoming</p>
        </div>
      );
    }

    if (props.item.soldout) {
      return (
        <div className="soldout-notice">
          <p className="ordertable-ticket-price">Sold out</p>
          <link href="http://schema.org/OutOfStock" itemProp="availability" />
        </div>
      );
    }

    const quantityOptions: Option[] = [];
    for (let i = props.item.min; i <= props.item.max; i++) {
      quantityOptions.push({ label: i.toString(), value: i.toString() });
    }

    quantityOptions.unshift({ label: '0', value: '0' });

    const minLabel =
      props.item.min && props.item.min > 1 ? (
        <span className="quantity-input-prompt">Min {props.item.min}</span>
      ) : null;

    return (
      <React.Fragment>
        <span className="select-tickets-selector">
          <StyledSelect
            value={quantity.toString()}
            options={quantityOptions}
            name={`items[${id}][quantity]`}
            id={`items-${id}-quantity`}
            onChange={onChange}
            locale={props.locale}
            disabled={props.busy}
          />
        </span>
        <link href="http://schema.org/InStock" itemProp="availability" />
        {minLabel}
      </React.Fragment>
    );
  };

  const inCart = !updating && quantity > 0;
  const rowClass = classNames({
    item: true,
    'select-tickets-row': true,
    'in-cart': inCart,
    'is-soldout': props.item.soldout,
    'adding-to-cart': updating,
  });

  return (
    <div
      className={rowClass}
      data-item={id}
      id={`item-${id}`}
      itemProp="offers"
      itemType="http://schema.org/Offer"
    >
      <div className="select-tickets-col item-col">
        <h2 className="ordertable-ticket-name">
          <span itemProp="name">{title}</span>
        </h2>
        <div className="ordertable-occurrence-description">{occurrence_description}</div>
        <div
          className="ordertable-ticket-description"
          itemProp="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="select-tickets-col price-col form-wrapper">
        <span className="mobile-label">Price</span>
        <span className="ordertable-ticket-price" itemProp="price">
          {price}
        </span>
        <span className="ordertable-ticket-currency optional-currency">{currency}</span>
      </div>
      <div className="select-tickets-col select-col">{renderQuantitySelector()}</div>
      <div className="select-tickets-col proceed-col">
        <div className="select-tickets-proceed-image">
          <AddingToCartImage />
          <InCartProceedImage enabled={inCart} updating={updating} />
        </div>
      </div>
    </div>
  );
};

export default Item;
