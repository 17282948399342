// https://developer.mozilla.org/en-US/docs/DOM/document.cookie
/**
 * allCookies.setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure)
 *
 * @argument sKey (String): the name of the cookie;
 * @argument sValue (String): the value of the cookie;
 * @optional argument vEnd (Number - finite or Infinity, String, Date object or null): the max-age in seconds (e.g.
 * 31536e3 for a year) or the expires date in GMTString format or in Date Object format; if not specified it will
 * expire at the end of session;
 * @optional argument sPath (String or null): e.g., "/", "/mydir"; if not specified, defaults to the current path
 * of the current document location;
 * @optional argument sDomain (String or null): e.g., "example.com", ".example.com" (includes all subdomains) or
 * "subdomain.example.com"; if not specified, defaults to the host portion of the current document location;
 * @optional argument bSecure (Boolean or null): cookie will be transmitted only over secure protocol as https;
 * @return undefined;
 **/

const allCookies = {
  getItem: function (sKey) {
    if (!sKey || !this.hasItem(sKey)) {
      return null;
    }
    return unescape(
      document.cookie.replace(
        new RegExp(
          '(?:^|.*;\\s*)' +
            escape(sKey).replace(/[-.+*]/g, '\\$&') +
            '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'
        ),
        '$1'
      )
    );
  },
  setItem: function (
    sKey: string,
    sValue: any,
    vEnd?: string | number | Date,
    sPath?: string,
    sDomain?: string,
    bSecure?: boolean
  ) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return;
    }
    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity ? '; expires=Tue, 19 Jan 2038 03:14:07 GMT' : '; max-age=' + vEnd;
          break;
        case String:
          sExpires = '; expires=' + vEnd;
          break;
        case Date:
          sExpires = '; expires=' + (vEnd as Date).toUTCString();
          break;
      }
    }
    document.cookie =
      escape(sKey) +
      '=' +
      escape(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '');
  },
  removeItem: function (sKey, sPath) {
    if (!sKey || !this.hasItem(sKey)) {
      return;
    }
    document.cookie =
      escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sPath ? '; path=' + sPath : '');
  },
  hasItem: function (sKey: string): boolean {
    return new RegExp('(?:^|;\\s*)' + escape(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=').test(
      document.cookie
    );
  },
  keys: /* optional method: you can safely remove it! */ function () {
    /* eslint-disable no-useless-backreference */
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:=[^;]*)?;\s*/);
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = unescape(aKeys[nIdx]);
    }
    return aKeys;
  },
};

export default allCookies;
