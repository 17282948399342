// needed for data-method="post" to add upsell items
import Siema from 'siema';

const initCarousel = function (container) {
  const shake = function (elem) {
    elem.classList.add('shake');
    window.setTimeout(() => elem.classList.remove('shake'), 1000);
  };

  const carousel = new Siema({
    selector: container.querySelector('.checkout-upsell-items'),
    perPage: {
      560: 2,
      860: 3,
      1200: 4,
    },
    onInit() {
      return this.selector.classList.add('show');
    },
  });

  const prev = container.querySelector('.prev-button');
  const next = container.querySelector('.next-button');

  prev.addEventListener('click', function (event) {
    event.preventDefault();
    carousel.prev();
    if (carousel.innerElements.length <= carousel.perPage) {
      shake(prev);
    }
  });

  next.addEventListener('click', function (event) {
    event.preventDefault();
    carousel.next();
    if (carousel.innerElements.length <= carousel.perPage) {
      shake(next);
    }
  });
};

const init = () => {
  const container = document.querySelector('.checkout-upsell-item-container');
  if (container) {
    initCarousel(container);
  }
};

export default function () {
  init();
  $(document).on('tickit.update-ui', () => {
    init();
  });
}
