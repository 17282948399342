import { Item, Items } from '../seatmap-setup/store';
import { SeatData } from './types';

interface AvailableItemToEmit extends Item {
  selected: boolean;
}

interface SeatDataToEmit {
  qid: string;
  description: string;
  available: boolean;
  inCurrentCart: boolean;
  availableItems: { [key: string]: AvailableItemToEmit };
}

// format seat data to emit to the mobile app's web view
export default function seatDataToEmit(
  qid: string,
  seatData: SeatData,
  allPossibleItems: Items
): SeatDataToEmit {
  const { s, d, i, cartItemId } = seatData;
  const available = s === 'a';
  const inCurrentCart = s === 'c';

  const availableItems = {};
  i.forEach((itemID) => {
    if (allPossibleItems[itemID]) {
      const selected = String(cartItemId) === String(itemID);
      availableItems[itemID] = { ...allPossibleItems[itemID], selected };
    }
  });

  return {
    qid,
    description: d,
    available,
    inCurrentCart,
    availableItems,
  };
}
