import React, { StrictMode } from 'react';
import PwycSelector from './index';
import { FreeformPresets } from './types';
interface Props {
  updateEndpoint: string;
  itemId: string;
  itemTitle: string;
  quantity: number;
  maxQuantity: number;
  freeformPresets: FreeformPresets;
  basePrice: number;
  freeformBasePrice: number;
  handleClose: () => void;
}
export default function Loader(props: Props) {
  return (
    <StrictMode>
      <PwycSelector {...props} />
    </StrictMode>
  );
}
