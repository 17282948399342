import 'whatwg-fetch';
import { AjaxResponse } from './types';
import getCSRFToken from '../../common/getCSRFToken';

function parseJSON(json: AjaxResponse): AjaxResponse {
  return json;
}

export function fetchData(endpoint: string) {
  return new Promise<AjaxResponse>((resolve, reject) => {
    fetch(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      // ensure we send the session ID across subdomains
      // https://medium.com/@Nicholson85/handling-cors-issues-in-your-rails-api-120dfbcb8a24
      credentials: 'include',
    })
      .then(function (response) {
        response.json().then((json) => resolve(parseJSON(json)));
      })
      .catch((error) => reject(error));
  });
}

export function postData(endpoint: string, itemID: string, quantity: number) {
  return new Promise<AjaxResponse>((resolve, reject) => {
    const items = {};
    items[itemID] = {
      quantity,
    };

    return fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      body: JSON.stringify({ items }),
      // ensure we send the session ID across subdomains
      // https://medium.com/@Nicholson85/handling-cors-issues-in-your-rails-api-120dfbcb8a24
      credentials: 'include',
    })
      .then(function (response) {
        response.json().then((json) => {
          resolve(parseJSON(json));
        });
      })
      .catch((error) => reject(error));
  });
}
