import $ from 'jquery';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import Loader from '../components/PwycSelector/Loader';
import { closeModal, openModal } from './publicModal';
import { PwycSelectorProps } from '../components/PwycSelector';

const OPEN_PWYC_SELECTOR_EVENT_TYPE = 'tickit-open-pwyc-selector';
const MODAL_ID = 'pwyc-selector';
let builderOverlay: HTMLDivElement;
let root: Root;

/* Some pages, like the checkout page, need a full refresh when the cart is updated */
let shouldRefreshAfterUpdate = false;

const doc = $(document);

const renderModal = (props: PwycSelectorProps) => {
  if (!root) {
    builderOverlay = document.querySelector('#public-global-modal .pgm-contents');
    root = createRoot(builderOverlay);
  }

  doc.on('tickit:public-modal-close', function (event, data) {
    if (!data || data.id !== MODAL_ID) return;
    if (root) {
      root.unmount();
      root = undefined;
    }

    doc.off('tickit:public-modal-close');
  });

  root.render(<Loader {...props} />);
  openModal({ id: MODAL_ID, hideCloseButton: true });
};

const onUpdate = () => {
  if (shouldRefreshAfterUpdate) {
    window.location.reload();
  }
};

const handleOpen = function (event: CustomEvent) {
  event.preventDefault();

  const target: HTMLElement = event.detail.originalTarget;
  const quantity = event.detail.quantity || 1;
  const freeformBasePrice = event.detail.freeformBasePrice;
  const container = target.closest('.select-tickets-row-pwyc') as HTMLElement;
  const freeformPresets = JSON.parse(container.dataset.freeformPresets);
  const basePrice = parseFloat(container.dataset.basePrice);
  const maxQuantity = parseInt(container.dataset.maxQuantity, 10);

  const props = {
    updateEndpoint: container.dataset.updateEndpoint,
    itemId: container.dataset.item,
    itemTitle: container.dataset.itemTitle,
    freeformPresets,
    basePrice,
    quantity,
    maxQuantity,
    freeformBasePrice,
    onUpdate,
    handleClose: closeModal,
  };

  renderModal(props);
};

export const dispatchOpenPwycSelectorEvent = (
  target: HTMLElement,
  quantity: number | undefined = undefined,
  freeformBasePrice?: number,
) => {
  const newEvent = new CustomEvent(OPEN_PWYC_SELECTOR_EVENT_TYPE, {
    detail: { originalTarget: target, quantity, freeformBasePrice },
    bubbles: true,
    cancelable: true,
  });
  document.dispatchEvent(newEvent);
};

function bindLinks(container?: HTMLElement) {
  if (!container) container = document.body;

  const rows = container.querySelectorAll('.select-tickets-row-pwyc');
  rows.forEach((row) => {
    const btns = row.querySelectorAll('input.freeform-base-price');

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function (originalEvent) {
        originalEvent.preventDefault();

        const quantity = parseInt(
          row.querySelector<HTMLSelectElement>('.quantity-input')?.value || '1',
          10,
        );

        const freeformBasePriceStr =
          row.querySelector<HTMLInputElement>('.freeform-base-price')?.value || '0';

        const freeformBasePrice = parseFloat(freeformBasePriceStr.replace(/[^\d.]/g, ''));

        dispatchOpenPwycSelectorEvent(
          originalEvent.target as HTMLElement,
          quantity,
          freeformBasePrice,
        );
      });
    }
  });
}

export default function init() {
  shouldRefreshAfterUpdate = $('.checkout-page').length > 0;
  document.addEventListener(OPEN_PWYC_SELECTOR_EVENT_TYPE, handleOpen);
  bindLinks();
}
