import Dropzone from 'dropzone';

const initQuestionUploaders = function(containers) {
  if (!document.querySelector('.questions-form')) return;

  if (containers.length < 1) return;

  containers.forEach(container => initQuestionUploader(container));
};

const initQuestionUploader = function(container) {
  const input = container.querySelector('.image-input');
  const helptext = container.querySelector('.image-helptext-inner');
  const submit = container.closest('form').querySelector('button[type="submit"]');

  const dropzone = new Dropzone(container.querySelector('.image-uploader'), {
    url: container.dataset.uploadPath,
    paramName: 'image[image]',
    maxFilesize: 5, // MB
    maxFiles: 1,
    thumbnailWidth: 420,
    thumbnailHeight: 420,
    thumbnailMethod: 'contain',
    addRemoveLinks: true,
    dictRemoveFile: '<i class="ti ti-close-light" />',
    dictCancelUpload: '<i class="ti ti-close-light" />',
    init: () => {
      return container.classList.add('initialized');
    }
  });

  const previewSavedFile = function() {
    // Create the mock file:
    const mockFile = { name: 'SavedImage', size: 12345 };
    // Add to dropzone file queue
    dropzone.files.push(mockFile);
    // Call the default addedfile event handler
    dropzone.emit('addedfile', mockFile);
    // And optionally show the thumbnail of the file:
    dropzone.emit('thumbnail', mockFile, container.dataset.savedImagePath);
    // Make sure that there is no progress bar, etc...
    dropzone.emit('complete', mockFile);
  };

  const resetState = function() {
    container.classList.remove('uploading');
    container.classList.remove('error');
    helptext.innerHTML = container.dataset.promptMessage;
    submit.disabled = false;
  };

  const uploadingState = function() {
    const savedImage = dropzone.files.find(file => {
      return file.name == 'SavedImage';
    });
    if (savedImage) dropzone.removeFile(savedImage);
    container.classList.add('uploading');
    container.classList.remove('error');
    helptext.innerHTML = container.dataset.uploadingMessage;
    submit.disabled = true;
  };

  if (container.dataset.savedImagePath) {
    previewSavedFile();
  }

  dropzone.on('dragover', function() {
    container.classList.add('dragover');
  });

  dropzone.on('dragleave', function() {
    container.classList.remove('dragover');
  });

  dropzone.on('addedfile', function() {
    uploadingState();
  });

  dropzone.on('processing', function() {
    uploadingState();
  });

  dropzone.on('uploadprogress', function() {
    uploadingState();
  });

  dropzone.on('sending ', function() {
    uploadingState();
  });

  dropzone.on('drop', function() {
    uploadingState();
    container.classList.remove('dragover');
  });

  dropzone.on('success', function(file) {
    resetState();
    const response = JSON.parse(file.xhr.response);
    if (response.success) {
      input.value = response.image_id;
    } else {
      dropzone.removeFile(file);
      input.value = '';
      helptext.innerHTML = response.errors.join(', ');
      container.classList.add('error');
    }
  });

  dropzone.on('error', function(file) {
    resetState();
    dropzone.removeFile(file);
  });

  dropzone.on('removedfile', function() {
    resetState();
    input.value = '';
  });
};

export default function() {
  initQuestionUploaders(document.querySelectorAll('.question-image-uploader'));

  document.addEventListener('tickit.update-qa-form', function() {
    initQuestionUploaders(document.querySelectorAll('.question-image-uploader:not(.initialized)'));
  });
}
