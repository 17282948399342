import { CartData } from './types';
import getCSRFToken from '../common/getCSRFToken';

function fetchCartStatus(cartPath: string) {
  const xhr: JQuery.jqXHR<CartData> = $.ajax({
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    url: cartPath,
    headers: {
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      // ensure we send the session ID across subdomains
      // https://medium.com/@Nicholson85/handling-cors-issues-in-your-rails-api-120dfbcb8a24
      withCredentials: true,
    },
  });

  return new Promise<CartData>((resolve, reject) => {
    xhr.then(function (data) {
      resolve(data);
    });

    xhr.fail(function (response) {
      // jquery getJSON 'fails' with an empty response even though the server status code
      // isn't actual a failure
      switch (response.status) {
        case 200:
        case 204:
        case 304: {
          const data: CartData = {
            currency: 'CAD',
            totalItems: 0,
          };
          resolve(data);
          break;
        }

        default: {
          reject(new Error(response.statusText));
        }
      }
    });
  });
}

export { fetchCartStatus };
