import $ from 'jquery';
import getCSRFToken from '../../common/getCSRFToken';
interface SetupIntentPayload {
  setup_intent_id: string;
  client_secret: string;
  customer: string;
}
const fetchSetupIntent = (setupIntentPath: string): Promise<SetupIntentPayload> => {
  const xhr: JQuery.jqXHR<SetupIntentPayload> = $.ajax({
    type: 'POST',
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    url: setupIntentPath,
    headers: {
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      withCredentials: true,
    },
  });

  return new Promise<SetupIntentPayload>((resolve, reject) => {
    xhr.then(function (data) {
      resolve(data);
    });

    xhr.fail(function (response) {
      reject(new Error(response.statusText));
    });
  });
};

export default fetchSetupIntent;
