import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { reportWarning } from '../../common/errorReporting';
import { googleAnalyticsV4Params, trackAnalyticsV4Event } from '../../frontend/analytics';
import { ICardData, IPretokenizedCardData } from '../../frontend/types';
import { ValidationBeforeCheckoutResponse } from '../../frontend/validateCheckoutBeforeConfirming';
import CheckoutErrors from './CheckoutErrors';
import ClimateBadge from './ClimateBadge';
import Optins from './Optins';

interface Props {
  onPaymentIntentSucceeded: (
    paymentIntentID: string,
    checkoutJobID: string,
    cardData: IPretokenizedCardData,
  ) => void;
  validateCheckout: (cardData: IPretokenizedCardData) => Promise<ValidationBeforeCheckoutResponse>;

  paymentPlanID: number | undefined;
  checkoutJobID: string;
  checkoutParams: ICardData;
  optinMode: 'automatic_optin' | 'default_optin' | 'default_optout';
}

const controlKlass = (errors: string | null) => {
  return errors ? 'control invalid' : 'control';
};

const isCanceledPaymentIntentError = (error): boolean => {
  if (error.code !== 'payment_intent_unexpected_state') return false;
  const pi = error['payment_intent'];
  if (!pi) return false;
  return pi['cancellation_reason'] === 'abandoned';
};

const PaymentElementForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const nameInput = useRef(null);
  const emailInput = useRef(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasOrderDetails, setHasOrderDetails] = useState<boolean>(
    props.checkoutParams?.name?.length > 0 && props.checkoutParams?.email?.length > 0,
  );
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleInputChange = () => {
    setHasOrderDetails(nameInput.current?.value.length > 0 && emailInput.current?.value.length > 0);
  };

  const getBillingDetails = (): IPretokenizedCardData => {
    return {
      name: nameInput.current.value,
      email: emailInput.current.value,
      terms_accepted: termsAccepted,
      payment_plan_id: props.paymentPlanID,
    };
  };

  const onPaymentElementChange = (e: StripePaymentElementChangeEvent) => {
    if (e.complete) {
      trackAnalyticsV4Event('add_payment_info', googleAnalyticsV4Params());
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setSubmitting(true);
    setErrorMessage(null);

    const orderDetails = getBillingDetails();

    trackAnalyticsV4Event('begin_checkout', googleAnalyticsV4Params());

    props
      .validateCheckout(orderDetails)
      .then(() => {
        console.log('stripe: validated and calling confirmPayment');
        stripe
          .confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
              return_url: window.location.href,
              payment_method_data: {
                billing_details: {
                  name: orderDetails.name,
                  email: orderDetails.email,
                },
              },
            },
          })
          .then((result) => {
            console.log('stripe: confirmPayment resolved', result);
            const { error, paymentIntent } = result;

            if (error) {
              if (error.type === 'card_error' || error.type === 'validation_error') {
                setErrorMessage(error.message);
              } else if (isCanceledPaymentIntentError(error)) {
                setErrorMessage(window.Tickit_Checkout_i18n.canceled_payment_intent);
              } else {
                reportWarning('StripeConfirmPaymentError', error);
                const message = error.message || window.Tickit_Checkout_i18n.gateway_error;
                setErrorMessage(message);
              }
              setSubmitting(false);
              return;
            }

            if (paymentIntent) {
              console.log('stripe: HERE IS THE PAYMENT INTENT', paymentIntent);
              if (paymentIntent.status === 'requires_action') {
                console.log('stripe: 3D Secure authentication required');
                stripe.handleCardAction(paymentIntent.client_secret).then((handleResult) => {
                  console.log('stripe: handleCardAction result', handleResult);
                  if (handleResult.error) {
                    // 3D Secure authentication failed or was canceled
                    setErrorMessage(handleResult.error.message);
                    setSubmitting(false);
                  } else if (handleResult.paymentIntent.status === 'succeeded') {
                    // Payment succeeded after 3D Secure
                    props.onPaymentIntentSucceeded(
                      handleResult.paymentIntent.id,
                      props.checkoutJobID,
                      orderDetails,
                    );
                  } else {
                    // Handle unexpected statuses
                    setErrorMessage('Unexpected status: ' + handleResult.paymentIntent.status);
                    setSubmitting(false);
                  }
                });
              } else if (paymentIntent.status === 'succeeded') {
                props.onPaymentIntentSucceeded(paymentIntent.id, props.checkoutJobID, orderDetails);
                return;
              }
            }

            throw new Error('confirmPayment returned no paymentIntent');
          })
          .catch((error) => {
            reportWarning(error);
            setErrorMessage(error.toString());
            setSubmitting(false);
            return;
          });
      })
      .catch((error) => {
        console.warn(error);

        if (error.redirect_url) {
          alert(error.error);
          window.location.href = error.redirect_url;
          return;
        }

        setErrorMessage(error.error);
        setSubmitting(false);
        return;
      });
  };

  const enableSubmit = !!stripe && !submitting && termsAccepted && hasOrderDetails;

  const formClass = classNames({ 'form-wrapper': true, 'form-submitting': submitting });
  return (
    <form onSubmit={handleSubmit} className={formClass}>
      <CheckoutErrors generalError={errorMessage} />
      <div className="control-group">
        <div className="control">
          <label htmlFor="checkout_name">{window.Tickit_Checkout_i18n.field_name}</label>
          <input
            autoComplete="name"
            required={true}
            type="text"
            name="checkout[name]"
            id="checkout_name"
            defaultValue={props.checkoutParams.name}
            onChange={handleInputChange}
            ref={nameInput}
          ></input>
        </div>
      </div>
      <div className="control-group">
        <div className="control" data-field="email">
          <label htmlFor="checkout_email">
            {window.Tickit_Checkout_i18n.field_email}{' '}
            <span className="hint email-hint">
              ({window.Tickit_Checkout_i18n.field_email_hint})
            </span>
          </label>
          <input
            autoComplete="email"
            required={true}
            type="email"
            ref={emailInput}
            defaultValue={props.checkoutParams.email}
            name="checkout[email]"
            onChange={handleInputChange}
            id="checkout_email"
          />
        </div>
      </div>
      <div className="control-group">
        <div className={controlKlass(errorMessage)}>
          <PaymentElement
            onChange={onPaymentElementChange}
            options={{
              terms: { card: 'never' },
              fields: {
                // we take these explicitly from user input, not apple/google pay
                billingDetails: {
                  name: 'never',
                  email: 'never',
                },
              },
            }}
          />
        </div>
      </div>
      <Optins
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        optinMode={props.optinMode}
      />

      <div className="control-group checkout-control-group">
        <div className="checkout-button-control" hidden={!stripe || !elements}>
          <button className="btn-lg" type="submit" disabled={!enableSubmit}>
            {submitting
              ? window.Tickit_Checkout_i18n.buy_button_submitting
              : window.Tickit_Checkout_i18n.buy_button}
          </button>
        </div>
      </div>
      <ClimateBadge />
    </form>
  );
};

export default PaymentElementForm;
