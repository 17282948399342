import { Appearance } from '@stripe/stripe-js';
const ERROR_COLOR = '#ea1c24';

const stripeElementsAppearance = (BRAND_PRIMARY = '#A08F68') => {
  return {
    theme: 'stripe',
    variables: {
      colorPrimary: BRAND_PRIMARY, // '#6b6b6b',
      colorBackground: '#ffffff',
      colorIconTabSelected: '#ffffff',
      colorIconTabHover: '#ffffff',
      colorText: '#6b6b6b',
      colorDanger: '#ea1c24',
      fontFamily: 'Open Sans, sans-serif',
      spacingUnit: '6px',
      borderRadius: '0',
      spacingGridRow: '24px',
    },

    rules: {
      '.Label': {
        color: '#6b6b6b',
        marginBottom: '6px',
        fontSize: '18px',
        fontFamily: 'Open Sans, sans-serif',
        lineHeight: '24px',
        fontWeight: '300',
      },
      '.Input': {
        padding: '16px 18px 14px',
        fontSize: '20px',
        lineHeight: '24px',
        border: '1px solid #c9c9c9',
        boxShadow: '0px 0px 0px 2px #f2f2f2 inset',
      },
      '.Input:focus': {
        border: `1px solid ${BRAND_PRIMARY}`,
        outline: '0',
        boxShadow: '0px 0px 0px 2px #f2f2f2 inset',
      },
      '.Input--invalid': {
        color: ERROR_COLOR,
        border: `1px solid ${ERROR_COLOR}`,
        outline: '0',
        boxShadow: '0px 0px 0px 2px #f2f2f2 inset',
      },
      '.Input::placeholder': {
        color: '#c9c9c9',
      },
      '.Block': {
        border: '1px solid #c9c9c9',
        boxShadow: '0px 0px 0px 2px #f2f2f2 inset',
      },
      '.Tab': {
        color: '#fff',
        backgroundColor: '#242424',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: '700',
        fontSize: '15px',
        border: 'none',
        boxShadow: 'none',
      },
      '.Tab:hover': {
        color: '#fff',
      },
      '.Tab--selected': {
        color: '#fff',
        backgroundColor: '#A08F68',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: '700',
        fontSize: '15px',
        border: 'none',
        boxShadow: 'none',
      },
      '.Tab--selected:hover': {
        color: '#fff',
        backgroundColor: '#A08F68',
      },
      '.Tab--selected:focus': {
        border: 'none',
        boxShadow: 'none',
      },
      '.TabLabel:hover': {
        color: '#fff',
      },
      '.TabLabel--selected:hover': {
        color: '#fff',
      },
    },
  } as Appearance;
};

export { stripeElementsAppearance };
