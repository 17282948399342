import { trackEvent } from './analytics';
import { scrollToElement } from './utils';

const bindEventTOC = () => {
  $('.event-toc-item').each(function (idx, el) {
    const item = $(el);
    const href = item.find('a[href]').first().attr('href');

    item.on('click', function (evt) {
      evt.preventDefault();
      window.location.assign(href);
    });
  });
};

const bindEventDescriptionImages = () => {
  $('.event-description-image').click(function (event) {
    event.preventDefault();
    $(this).toggleClass('is-expanded');
  });
};

const bindScrolling = () => {
  $('.tickit-internal').click(function (event) {
    const target = $($(this).attr('href'));
    if (target.length > 0) {
      event.preventDefault();
      scrollToElement(target);
      trackEvent('click-internal-link', target[0].id);
      return false;
    }
  });
};

const bindMoreInfoLinks = () =>
  $('.select-tickets-info-toggle').click(function (event) {
    event.preventDefault();
    $(this).closest('.select-tickets-row').toggleClass('show-additional-description');
    trackEvent('select-tickets', 'toggle-additional-description');
  });

// currently only used for the tooltip hints
const bindHints = () => {
  $('.hint-toggle[data-target]').click(function (event) {
    event.preventDefault();
    const targetID = $(this).attr('data-target');
    const target = $(`#${targetID}`);
    if (!target.length) return;

    if (target.attr('hidden')) {
      target.removeAttr('hidden');
    } else {
      target.attr('hidden', true);
    }
  });
};

export default function init() {
  bindScrolling();
  bindHints();
  bindEventTOC();
  bindEventDescriptionImages();
  bindMoreInfoLinks();
}
