import { trackEvent } from './analytics';

const VISIBLE_CLASS = 'visible';

// show a tooltip for disabled nav items (i.e. the 'Orders' item when before a user checks out)
export default function init() {
  Array.from(
    document.querySelectorAll('.nav-item.nav-item-disabled .nav-item-disabled-tooltip')
  ).forEach(function(tooltip) {
    const navItem = tooltip.closest('.nav-item');
    let showingTooltip = false;

    navItem.addEventListener(
      'click',
      function() {
        const maybeCloseTooltip = event => {
          // don't close the tooltip if we're clicking a link within it
          if (navItem.contains(event.target)) return;

          tooltip.classList.remove(VISIBLE_CLASS);
          tooltip.setAttribute('aria-hidden', true);
          showingTooltip = false;
          document.removeEventListener('click', maybeCloseTooltip);
        };

        if (showingTooltip) return;
        tooltip.classList.add(VISIBLE_CLASS);
        tooltip.removeAttribute('aria-hidden');
        showingTooltip = true;

        document.addEventListener('click', maybeCloseTooltip, true);

        trackEvent('nav', 'show-nav-item-disabled-tooltip');
      },
      true
    );
  });
}
