import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { TicketListQuantity } from './quantity';
import CurrentSeat from './CurrentSeat';

export class TicketListCurrentItem extends React.Component {
  constructor(props) {
    super(props);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.initialQuantity = Object.keys(this.props.itemSeats).length;
    this.state = { quantity: this.initialQuantity, quantityChanged: false, disabled: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.busy) {
      // don't change the quantities until we've got a server response
      this.setState({ disabled: true });
      return;
    }
    const nextQuantity = Object.keys(nextProps.itemSeats).length;

    this.setState({ quantity: nextQuantity, quantityChanged: false, disabled: false });
  }

  onQuantityChange(quantity) {
    this.setState({ quantity: quantity, quantityChanged: quantity != this.initialQuantity });
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.handleUpdateItem(this.props.itemId, this.state.quantity);
  };

  renderQuantityWarning() {
    if (!this.state.quantityChanged) return;

    if (this.state.quantity < 1) {
      // TODO: i18n
      return <p className="warning">Clicking search will remove this ticket completely</p>;
    }

    // TODO: i18n
    return (
      <p className="warning">
        Changing the quantity may result in completely different seats being selected
      </p>
    );
  }

  render() {
    const individualSeats = map(this.props.itemSeats, (seat, qid) => {
      return (
        <CurrentSeat
          {...seat}
          qid={qid}
          key={qid}
          highlighted={qid === this.props.selectedQID}
          price={this.props.price}
          itemTitle={this.props.title}
          allowSelection={this.props.allowSelection}
          handleRemoveSeat={this.props.handleRemoveSeat}
          disabled={this.state.disabled}
        />
      );
    });

    return (
      <div className="seatmap-ticket-type seatmap-ticket-type-in-cart">
        <form onSubmit={this.onSubmit} disabled={this.state.disabled} className="form">
          <span className="current-title">{this.props.title}</span>

          <TicketListQuantity
            min={this.props.min}
            max={this.props.max}
            value={this.state.quantity}
            onChange={this.onQuantityChange}
            disabled={this.state.disabled}
          />

          <button type="submit" disabled={this.state.disabled} className="seatmap-cart-button">
            Search
          </button>
        </form>
        {this.renderQuantityWarning()}
        {individualSeats}
      </div>
    );
  }
}

TicketListCurrentItem.propTypes = {
  allowSelection: PropTypes.bool,
  title: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  itemSeats: PropTypes.object.isRequired,
  busy: PropTypes.bool,
  handleUpdateItem: PropTypes.func.isRequired,
  handleRemoveSeat: PropTypes.func.isRequired,
  price: PropTypes.string,
  selectedQID: PropTypes.string,
};
