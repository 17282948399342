import { reportWarning, reportError } from '../common/errorReporting';
import getCSRFToken from '../common/getCSRFToken';
import { CART_UPDATED_EVENT_TYPE } from './cart';
import { ICart } from './types';

/** Tell the server that payment has started client side
 *
 * On the server we check `cart_expires_at` to see if we're nearing the expiry time and,
 * if so, we extend the time so the user does not timeout during checkout.  This is
 * especially important for Stripe's various slow payment slows like 3D Secure and Apple/Google pay.
 */
function startCartPaying(payingPath: string): Promise<ICart> {
  const xhr: JQuery.jqXHR<ICart> = $.ajax({
    type: 'POST',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    dataType: 'json',
    url: payingPath,
    headers: {
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      withCredentials: true,
    },
  });

  return new Promise<ICart>((resolve, reject) => {
    xhr.then(function (data) {
      const event = new CustomEvent<ICart>(CART_UPDATED_EVENT_TYPE, { detail: data });
      document.dispatchEvent(event);

      try {
        console.log('startCartPaying', JSON.stringify(data));
      } catch (e) {
        console.error(e);
        reportError('startCartPaying error', e);
      }

      resolve(data);
    });

    xhr.fail(function (response) {
      const data = response.responseJSON;
      if (!data) {
        console.warn({
          status: response.status,
          statusText: response.statusText,
          responseText: response.responseText,
          response,
          payingPath,
        });
        reportWarning('startCartPaying error with no data in response');
        reject(window.Tickit_Checkout_i18n.something_went_wrong);
        return;
      }

      reject(new Error(data.error));
    });
  });
}

export default startCartPaying;
