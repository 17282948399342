export type LogArgument = string | Error | object | Date | any[];

export function reportError(...args: any[]): void {
  console.error(...args);
  const [obj] = args;

  if (window.Sentry) {
    window.Sentry.captureException(obj);
  }
}

export function reportWarning(...args: any[]): void {
  console.warn(...args);

  const [obj] = args;

  if (window.Sentry) {
    window.Sentry.captureMessage(obj.toString());
  }
}
