import React from 'react';

const Waiting: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="page-header">
      <div className="page-header-main">
        <div className="page-header-main-inner">
          <p className="announcement">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Waiting;
