import React from 'react';
import { ReactSVG } from 'react-svg';

interface Props {
  path: string;
  handleMapReady: (svg: SVGSVGElement) => void;
}

export default class SeatmapMap extends React.Component<Props> {
  firstRun = true;

  // ReactSVG wants to re-render the map regardless of what props are passed so
  // we short-circuit it here
  shouldComponentUpdate() {
    return false;
  }

  handleMapError = (err: unknown) => {
    if (err) {
      console.error(err);
      return;
    }
  };

  handleMapReady = (svg: SVGSVGElement) => {
    if (this.firstRun) {
      this.firstRun = false;
      this.props.handleMapReady(svg);
    }
  };

  render() {
    return (
      <ReactSVG
        src={this.props.path}
        afterInjection={this.handleMapReady}
        onError={this.handleMapError}
        className="seatmap-map-frame"
      />
    );
  }
}
