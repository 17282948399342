import { select, selectAll } from 'd3-selection';
import { transition } from 'd3-transition';
import { zoom, zoomIdentity } from 'd3-zoom';

export default {
  select,
  selectAll,
  zoom,
  zoomIdentity,
  transition,
};
