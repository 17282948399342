const renderBanner = function (msg) {
  const div = $(`\
<div style="display: block; position: absolute; position: fixed; z-index: 99999; top: 0; left: 0; width: 100%; min-height: 48px; font: bold 16px/24px sans-serif; padding: 24px; border-bottom: 3px solid #A08F68; text-align: center; letter-spacing: 1px; color: white; background: #444444; box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);">
${msg}
</div>\
`);
  $('body').prepend(div);
};

const checkIFrame = function () {
  let inIframe;
  try {
    inIframe = window.self !== window.top;
  } catch (error) {
    inIframe = true;
  }

  if (window.location.search.indexOf('iframe=1') > -1 && !inIframe) {
    renderBanner(`\
It looks like you’re loading the minimal version of our ticket store.
<a href="?" style="color:#fff;">Go to the full version?</a>\
`);
  }
};

// from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
const checkCookies = function (cookieDomain, storeURL) {
  const cookieBase = `cookietest=1; path=/; domain=${cookieDomain}; secure`;
  document.cookie = `${cookieBase}; SameSite=none`;
  const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;

  // Delete cookie
  document.cookie = `${cookieBase}; max-age=0`;

  if (!cookiesEnabled) {
    renderBanner(
      `Cookies must be enabled before you can buy tickets with the embedded storefront. <br><a href="${storeURL}" style="color:white;text-decoration:underline;border:0;">Click here to buy your tickets directly.</a>`
    );
  }
};

export default function init(cookieDomain, storeURL) {
  checkIFrame();
  checkCookies(cookieDomain, storeURL);
}
