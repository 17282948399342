import React from 'react';
import { createRoot } from 'react-dom/client';
import Seatmap from '../seatmap/index';
import { ISeatmapComponent } from '../seatmap/types';
const componentRef = React.createRef();

let eventID: string;
let seatmapOverlay: HTMLDivElement;

const loadSeatmap = (props) => {
  if (!eventID) throw new Error('Event ID is missing');

  const { svgPath } = props;

  if (componentRef.current) {
    (componentRef.current as ISeatmapComponent).reopen();
  } else {
    seatmapOverlay = document.createElement('div');
    seatmapOverlay.id = 'seatmap-overlay';
    seatmapOverlay.className += ' seatmap-overlay';
    document.getElementById('page').appendChild(seatmapOverlay);
  }

  const root = createRoot(seatmapOverlay);
  root.render(<Seatmap svg={svgPath} eventID={eventID} {...props} ref={componentRef} />);
};

const onOpenSeatmapClick = function (event: MouseEvent) {
  event.preventDefault();

  if (!window.Modernizr.websockets) {
    alert('Sorry your browser is out of date. Please update your browser before selecting seats.');
    return false;
  }

  const target: HTMLElement = event.target as HTMLElement;

  eventID = target.dataset.eventId;
  const hideCheckout: boolean = 'hideCheckout' in target.dataset;

  const props = {
    orderID: target.dataset.orderId,
    hideCheckout: hideCheckout,
    endpoint: target.dataset.endpoint || `/events/${eventID}/seats.json`,
    svgPath: target.dataset.svgPath,
    newCheckoutURL: target.dataset.newCheckoutUrl,
  };

  loadSeatmap(props);
};

window.bindSeatmapLinks = function (container?: HTMLElement) {
  if (!container) container = document.body;

  const btns = container.querySelectorAll('.btn-open-seatmap');

  for (let i = 0; i < btns.length; i++) {
    btns[i].addEventListener('click', onOpenSeatmapClick);
  }
};

export default function init() {
  window.bindSeatmapLinks();
}
