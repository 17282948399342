/*
  This is the primary checkout flow for payment gateways that support tokenization (which is most of them) EXCLUDING Stripe.

  The basic steps are

  ### TOKENIZE

  Pass the credit card data to the processor for tokenization using the TokenizeCardsController

  The final product is a StoredPaymentSource record with a paysrc_id that can safely be passed to
  a background job (no PCI sensitive data is included) and we can attempt to charge the credit card
  at our leisure.

  The JSON endpoint returns an ITokenizeResult object.

  ### SUBMIT CHECKOUTJOB AND POLL FOR RESPONSE

  The actual charging of the card, ticket generation, etc... happens via a CheckoutJob that runs in
  the background.  It receives the `paysrc_id`, looks up the payment source, validates the order,
  builds the payment plan, charges the payment source and generates tickets.

  The JSON endpoint returns an ICheckoutResult object.
 */

import jQuery from 'jquery';
import getCSRFToken from '../common/getCSRFToken';
import { trackEvent } from './analytics';
import { CheckoutError, createErrorFromJSON, createErrorFromXHR } from './checkoutErrors';
import tokenizeCard from './checkoutTokenizeCard';
import checkoutValidationErrors, { renderErrors } from './checkoutValidationErrors';
import { ICardData, ICheckoutResult } from './types';

// export jQuery to global window so its available to jquery-ujs
window.jQuery = jQuery;
// require('jquery-ujs');

export const SUBMITTING_CLASS = 'submitting-tokenized-form';
const USE_HISTORY_API = window.Modernizr.history;

let body: JQuery = undefined;
let form: JQuery<HTMLFormElement> = undefined;
let initialTitle: string = undefined;
const initialURL = document.location.pathname;
let checkoutURL: string = undefined;
let tokenizeCardURL: string = undefined;
let checkoutParams: ICardData = {};
let paymentPlanID: number = undefined;

// cardData may already exist if this originates from the PaymentRequest API
const checkCheckoutJob = (data: ICheckoutResult, resolve, reject) => {
  switch (data.status) {
    case 'pending': {
      setTimeout(() => {
        jQuery
          .ajax({
            dataType: 'json',
            url: data.status_url,
            xhrFields: {
              withCredentials: true,
            },
            headers: {
              Accept: 'application/json; charset=utf-8',
              'X-CSRF-Token': getCSRFToken(form[0]),
            },
          })
          .then((data: ICheckoutResult) => {
            return checkCheckoutJob(data, resolve, reject);
          });
      }, 350);
      break;
    }
    case 'failed': {
      trackEvent('checkout', 'checkout-job-failed');

      try {
        if (data && data.errors) {
          reject(createErrorFromJSON(data));
          return;
        }
      } catch (e) {
        console.error(e);
      }

      reject(new CheckoutError(window.Tickit_Checkout_i18n.something_went_wrong));

      break;
    }
    case 'succeeded': {
      trackEvent('checkout', 'checkout-job-succeeded');
      resolve(data);
      break;
    }
  }
};

export const submitCheckout = (checkoutData) => {
  return new Promise<ICheckoutResult>((resolve, reject) => {
    jQuery
      .ajax({
        type: 'POST',
        url: checkoutURL,
        xhrFields: {
          withCredentials: true,
        },
        headers: {
          Accept: 'application/json; charset=utf-8',
          'X-CSRF-Token': getCSRFToken(form[0]),
        },
        data: { checkout: checkoutData },
        dataType: 'json',
      })
      .then(function (data: ICheckoutResult) {
        if (data.status_url && USE_HISTORY_API) {
          window.history.replaceState(
            {},
            window.Tickit_Checkout_i18n.still_processing_title,
            data.status_url
          );
        }

        return checkCheckoutJob(data, resolve, reject);
      })
      .fail(function (xhr: JQuery.jqXHR) {
        trackEvent('checkout', 'checkout-error');
        reject(createErrorFromXHR(xhr));
      });
  });
};

const restoreForm = function () {
  body.removeClass(SUBMITTING_CLASS);
  window.TickitCheckoutSubmitting = false;

  // TODO: test this
  jQuery.rails.enableFormElement(form.find('button[type=submit]') as JQuery<HTMLElement>);
  if (USE_HISTORY_API) {
    history.replaceState({}, initialTitle, initialURL);
  }

  form.find('#checkout_paysrc_id').val('');
};

// non-PaymentRequest API flow
const onSubmit = function (event?: JQuery.Event) {
  event?.preventDefault();
  window.TickitCheckoutSubmitting = true;
  body.addClass(SUBMITTING_CLASS);
  return tokenizeCard(tokenizeCardURL, form, null, paymentPlanID)
    .then((checkoutData: ICardData) => {
      return submitCheckout(checkoutData);
    })
    .then((checkoutData: ICheckoutResult) => {
      window.parent.location.href = checkoutData.redirect_url;
    })
    .catch((checkoutError: CheckoutError) => {
      renderErrors(checkoutError);
      restoreForm();
    });
};

export default function ($form: JQuery<HTMLFormElement>) {
  form = $form;
  if (!form.hasClass('tokenized-checkout-form')) return;

  // we handle Stripe gateway for $0 orders here
  if (form.hasClass('stripejs') && !form.hasClass('no-payment-required')) return;

  body = jQuery('body');
  checkoutURL = form.attr('data-checkout-path');
  tokenizeCardURL = form.attr('data-tokenize-path');
  checkoutParams = JSON.parse(form.attr('data-checkout-params') || '{}');
  paymentPlanID = checkoutParams.payment_plan_id;
  initialTitle = jQuery('title').text();
  checkoutValidationErrors(form);
  form.on('submit', onSubmit);
}
