import debounce from 'lodash/debounce';
import { trackEvent } from './analytics';

export const OPEN_CLASS = 'open';
const HIDE_MAP_CLASS = 'hide-map';
const SELECTED_CLASS = 'selected';
const ANALYTICS_EVENT_CATEGORY = 'item-group';

// ItemGroupTemplate map front-end logic for the combo box and SVG map
//
// Inline SVG requires IE >= 9
// querySelectorAll and getBoundingClientRect requires IE >= 8
//
// We only support maps on IE 9+ (which accounted for 0.5% of traffic in 2016)
let supportsMap = true;

// classList requires IE >= 10 (and still doesn't support classList on SVG elements)
// so we polyfill these
//
// TODO: can we share these functions with the seatmaps?
const addClass = function (node, klass) {
  const existing = node.getAttribute('class') || '';
  if (existing.indexOf(klass) > -1) return;
  node.setAttribute('class', `${existing} ${klass}`);
};

const removeClass = function (node, klass) {
  const existing = node.getAttribute('class') || '';
  if (existing.indexOf(klass) === -1) return;
  node.setAttribute('class', existing.replace(klass, ''));
};

const showSubgroup = function (container, val) {
  const rows = container.find('.select-tickets-group-body .select-tickets-row');

  if (val.length > 0) {
    // show a specific subgroup, including tickets not in any subgroups (e.g. camping early arrival)
    const selector = `[data-subgroup-slug=${val}], [data-subgroup-slug='']`;
    rows.not(selector).addClass('select-tickets-row-hidden');
    rows.filter(selector).removeClass('select-tickets-row-hidden');
  } else {
    // show all subgroups
    rows.removeClass('select-tickets-row-hidden');
  }

  updateMapHighlights(container, val);
};

const updateMapHighlights = function (container, val) {
  if (!supportsMap) return;

  for (const zoneNode of Array.from(container[0].querySelectorAll('.map-zone'))) {
    const slug = zoneNode.getAttribute('id');
    const label = $(zoneNode).data('label');
    if (val.length && slug === val) {
      addClass(zoneNode, SELECTED_CLASS);
      addClass(label, SELECTED_CLASS);
    } else {
      removeClass(zoneNode, SELECTED_CLASS);
      removeClass(label, SELECTED_CLASS);
    }
  }
};

// update the positions of each map label
// - mapRect is the result of calling `getBoundingClientRect()`
// - zone is a SVG oath
const renderMapLabel = function (mapRect, zone) {
  const label = $(zone).data('label');

  const labelWidth = label.offsetWidth;
  const labelHeight = label.offsetHeight;

  const rect = zone.getBoundingClientRect();
  const rectWidth = rect.right - rect.left;
  const rectHeight = rect.bottom - rect.top;

  const left = rect.left - mapRect.left + rectWidth / 2 - labelWidth / 2; //- window.scrollX
  const top = rect.top - mapRect.top + rectHeight / 2 - labelHeight / 2; //- window.scrollY

  label.setAttribute('style', `visibility: visible; left: ${left}px; top: ${top}px`);
};

const onMapClick = function (event) {
  let val;
  event.preventDefault();
  event.stopPropagation();
  const className = event.currentTarget.getAttribute('class');
  if (className != null && className.indexOf('map-zone') > -1) {
    val = event.currentTarget.getAttribute('id');
  } else {
    val = '';
  }

  const container = $(event.currentTarget).closest('.select-tickets-group');
  const selectBox = container.find('.group-selector select');
  selectBox.val(val).trigger('change');
  trackEvent(ANALYTICS_EVENT_CATEGORY, 'map-click', val);
};

const onMapMouseEnter = function (event) {
  const zone = $(event.currentTarget);
  const label = zone.data('label');
  addClass(label, 'hover');
};

const onMapMouseLeave = function (event) {
  const zone = $(event.currentTarget);
  const label = zone.data('label');
  removeClass(label, 'hover');
};

const initMaps = () => {
  for (let mapNode of Array.from(document.querySelectorAll('.select-tickets-map .map-container'))) {
    const map = $(mapNode);
    const ticketGroup = map.closest('.select-tickets-group');
    const selectBox = ticketGroup.find('.group-selector select');
    const mapRect = mapNode.getBoundingClientRect();

    ticketGroup.find('[data-behavior=toggle-map]').click(function (event) {
      event.preventDefault();
      event.stopPropagation();
      const link = $(this);
      if (ticketGroup.hasClass(HIDE_MAP_CLASS)) {
        ticketGroup.removeClass(HIDE_MAP_CLASS);
        link.text(link.attr('data-hide-label'));
        trackEvent(ANALYTICS_EVENT_CATEGORY, 'unhide-map');
      } else {
        ticketGroup.addClass(HIDE_MAP_CLASS);
        link.text(link.attr('data-show-label'));
        trackEvent(ANALYTICS_EVENT_CATEGORY, HIDE_MAP_CLASS);
      }
    });

    // ok to click outside a map-zone -- we just clear any existing highlights
    for (let base of Array.from(map[0].querySelectorAll('svg'))) {
      // TODO: touch events as well
      base.addEventListener('click', onMapClick, false);
      base.addEventListener('touchstart', onMapClick, false);
    }

    for (let zoneNode of Array.from(map[0].querySelectorAll('.map-zone'))) {
      zoneNode.addEventListener('click', onMapClick, false);
      zoneNode.addEventListener('touchstart', onMapClick, false);
      zoneNode.addEventListener('mouseenter', onMapMouseEnter, false);
      zoneNode.addEventListener('mouseleave', onMapMouseLeave, false);
      const zone = $(zoneNode);

      // create the label DIV and set it's text using the values in the selectbox
      const labelNode = document.createElement('div');
      labelNode.className = 'select-tickets-map-label';
      const zoneID = zoneNode.getAttribute('id');
      const labelInnerNode = document.createElement('div');
      labelInnerNode.innerText = selectBox.find(`option[value=${zoneID}]`).text();
      labelNode.appendChild(labelInnerNode);

      mapNode.appendChild(labelNode);
      zone.data('label', labelNode);
      renderMapLabel(mapRect, zoneNode);
    }
  }
};

export const onResize = () => {
  if (!supportsMap) return;

  for (let map of Array.from(document.querySelectorAll('.select-tickets-map'))) {
    const mapRect = map.getBoundingClientRect();
    Array.from(map.querySelectorAll('.map-zone')).map((zone) => renderMapLabel(mapRect, zone));
  }
};

export default function () {
  if (window.Modernizr) {
    supportsMap = window.Modernizr.inlinesvg && window.Modernizr.queryselector;
  }

  const hasMaps = document.querySelector('.select-tickets-map');

  if (supportsMap && hasMaps) {
    initMaps();
    const lazyResize = debounce(onResize, 300, false);
    window.addEventListener('resize', lazyResize);
  }

  $('.select-tickets-group.expandable .select-tickets-group-header').click(function () {
    const header = $(this);
    const container = header.closest('.select-tickets-group');
    container.toggleClass(OPEN_CLASS);
    onResize();
    trackEvent(ANALYTICS_EVENT_CATEGORY, 'toggle-open');
  });

  const selector = $('.select-tickets-group-header .group-selector');
  selector.click((event) => event.stopPropagation());

  selector.find('select').change(function () {
    const selectBox = $(this);
    const val = selectBox.val();
    const container = selectBox.closest('.select-tickets-group');
    showSubgroup(container, val, selectBox);
    trackEvent(ANALYTICS_EVENT_CATEGORY, 'select-box', val);
  });
}
