import $ from 'jquery';
export const OPEN_CLASS = 'show-pgm';

const doc = $(document);
let body = undefined;
let publicModal = undefined;

// escape binding
const bindEscapeKey = (event) => {
  if (event.keyCode != 27) return;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  closeModal(event);
};

export const openPrerenderedModal = () => {
  body.addClass(OPEN_CLASS);
  publicModal.css('display', 'flex').hide().fadeIn(150);
};

// open modal
interface OpenModalProps {
  id: string;
  flexHeight?: boolean;
  hideCloseButton?: boolean;
  onCloseCallback?: () => void;
}
export const openModal = (props:OpenModalProps) => {
  if (!publicModal.length) return;
  doc.on('keyup', bindEscapeKey);

  const id = props.id;
  const flexHeight = props.flexHeight || false;
  const hiddenContent = $(`[data-pgm-content=${id}]`).contents();
  const modalContent = publicModal.find('.pgm-contents');
  const hideCloseButton = props.hideCloseButton || false;

  publicModal.toggleClass('pgm-flexheight', flexHeight);
  publicModal.toggleClass('pgm-header-close-button', props.hideCloseButton);
  publicModal.toggleClass('pgm-header-close-button', !!props.hideCloseButton);

  modalContent.empty().append(hiddenContent);
  publicModal.data('sourceId', id);
  publicModal.attr('data-pgm-id', id);
  openPrerenderedModal();
};

// close modal
export const closeModal = (event?:Event) => {
  if (event) event.preventDefault();



  const id = publicModal.data('sourceId');
  const modalContent = publicModal.find('.pgm-contents').contents();
  const hiddenContent = doc.find(`[data-pgm-content=${id}]`);



  publicModal.removeAttr('data-pgm-id');

  hiddenContent.empty().append(modalContent);
  body.removeClass(OPEN_CLASS);
  publicModal.fadeOut(150);
  doc.trigger('tickit:public-modal-close', { id });
  doc.off('keyup', bindEscapeKey);
};

// initialize
export default function () {
  body = doc.find('body');
  publicModal = doc.find('#public-global-modal');
  if (publicModal.length === 0) return;
  doc.on('click', '[data-pgm-trigger]', function(event) {
    event.preventDefault();
    const id = event.target.dataset.pgmTrigger;
    const flexHeight = !!event.target.dataset.pgmFlexHeight;
    openModal({ id, flexHeight });
  });
  doc.on('click', '.pgm-close-trigger', closeModal);
  doc.on('click', '.pgm-background', closeModal);
}
