import jQuery from 'jquery';
import React, { useEffect, useRef } from 'react';
import { scrollToElement } from '../../frontend/utils';
interface Props {
  generalError: string | undefined;
}

const CheckoutErrors: React.FC<Props> = (props) => {
  const { generalError } = props;
  const messagesRef = useRef(null);

  const scrollToErrors = () => {
    if (!messagesRef.current) return;
    scrollToElement(jQuery('#validation-notice'));
  };

  useEffect(scrollToErrors, [generalError]);

  if (!generalError) return null;

  return (
    <div
      id="validation-notice"
      className="validation-error-notice"
      role="alert"
      aria-live="assertive"
      aria-atomic={true}
      ref={messagesRef}
    >
      <div className="text">
        <p key="generalError">{generalError}</p>
      </div>
    </div>
  );
};

export default CheckoutErrors;
