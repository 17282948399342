import getCSRFToken from '../common/getCSRFToken';
import { trackEvent, trackAnalyticsV4Event } from './analytics';
import cartCountdown, { setCountdownRemaining, updateCountdown } from './cartCountdown';
import { fetchCartStatus } from './fetchCartStatus';
import { UPDATE_DISPLAY_EVENT } from './selectBoxes';
import { CartData, CartDataItem } from './types';
import { needToShowPreAddWarning, confirmPreAddWarning } from './preAddWarning';
export const BODY_HAS_ITEMS_CLASS = 'items-in-cart';
export const CHECKOUT_DISABLED_CLASS = 'disabled-checkout-from-event-btn';
const CHECKOUT_ALWAYS_ENABLED_CLASS = 'checkout-from-event-always-enabled-btn';
export const NAV_DISABLED_CLASS = 'nav-item-disabled';
export const CART_UPDATED_EVENT_TYPE = 'tickit-cart-updated';

let cartPath: string;

let body,
  itemRows,
  proceedBtns,
  checkoutFromEventBtn: JQuery<HTMLButtonElement>,
  quantityLabel: JQuery<HTMLSpanElement>,
  formElement: HTMLFormElement,
  selectInputs,
  freeformInputs,
  navCheckoutLink,
  redemptionCode,
  updateCartEndpoint;

// pre_add_to_cart_warning
const renderCartStatus = function (data: CartData) {
  setCountdownRemaining(data.remaining);
  updateCountdown();
  quantityLabel.text(data.totalItems);
  body.toggleClass(BODY_HAS_ITEMS_CLASS, data.totalItems > 0);
};

const updateItemsXHR = function () {
  const params = {
    items: {},
  };
  selectInputs.each(function (idx, el) {
    const input = $(el);
    const quantity = parseInt(String(input.val() || 0), 10);
    const comboOptions = input
      .closest('.select-tickets-row')
      .find(`#item-${input.attr('data-item-id')}-combo-options`)
      .val() as string;

    const itemParams = { quantity };

    if (comboOptions && comboOptions.length > 0) {
      itemParams['combo_options'] = JSON.parse(comboOptions);
    }
    params.items[input.attr('data-item-id')] = itemParams;
  });

  freeformInputs.each(function (idx, el) {
    const input = $(el);
    const itemID = input.attr('data-item-id');
    if (!params.items[itemID]) params.items[itemID] = {};
    params.items[itemID].freeform_base_price = input.val();
  });

  if (redemptionCode) {
    params['redemption_code'] = redemptionCode;
  }

  return $.ajax({
    type: 'POST',
    url: updateCartEndpoint,
    data: JSON.stringify(params),
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    xhrFields: {
      withCredentials: true,
    },
    headers: {
      // we explicitly set the Accept header to ensure it does not include '*/*'
      // otherwise we trigger https://github.com/rails/rails/issues/9940 and get HTML content
      // back instead of JSON
      Accept: 'application/json; charset=utf-8',
      'X-CSRF-Token': getCSRFToken(formElement),
    },
  });
};

const updateCheckoutLinks = function (data: CartData) {
  if (checkoutFromEventBtn.hasClass(CHECKOUT_ALWAYS_ENABLED_CLASS)) return;

  if (data.totalItems && data.totalItems > 0) {
    navCheckoutLink.removeClass(NAV_DISABLED_CLASS);
    checkoutFromEventBtn.removeClass(CHECKOUT_DISABLED_CLASS);
    checkoutFromEventBtn.prop('disabled', false);
  } else {
    navCheckoutLink.addClass(NAV_DISABLED_CLASS);
    checkoutFromEventBtn.addClass(CHECKOUT_DISABLED_CLASS);
    checkoutFromEventBtn.prop('disabled', true);
  }
};

const highlightCheckoutLink = () => {
  window.setTimeout(function () {
    proceedBtns.removeClass('highlight');
    navCheckoutLink.addClass('nav-item-updated');
    window.setTimeout(() => navCheckoutLink.removeClass('nav-item-updated'), 1100);
  }, 50);
};

// if not enough inventory was available we should reflect that in the select boxes
const updateItemRows = function (data: CartData) {
  itemRows.removeClass('adding-to-cart');
  const { cartItems } = data;

  if (!cartItems) return;

  itemRows.each(function () {
    const itemRow = $(this);
    const itemID = this.dataset.item;
    const cartItem: CartDataItem = cartItems[itemID] || { q: 0 };
    const itemQuantity = cartItem.q;
    const componentDescriptions = (cartItem.combo_options || []).map((option) => option.item_title);

    const componentDescriptionContainer = itemRow.find('.select-tickets-multipack-descriptions');

    const listItems = componentDescriptions.map((description) => `<li>${description}</li>`);

    componentDescriptionContainer.find('ul').html(listItems.join(''));

    if (componentDescriptions.length > 0) {
      componentDescriptionContainer.removeAttr('hidden');
    } else {
      componentDescriptionContainer.attr('hidden', 'hidden');
    }

    if (cartItem.combo_options) {
      const comboOptions = itemRow.find(`#item-${itemID}-combo-options`);
      comboOptions.val(JSON.stringify(cartItem.combo_options));
    }
    itemRow.toggleClass('in-cart', itemQuantity > 0);

    const input = itemRow.find(`#item-${itemID}-input`);
    const displayedQuantity = parseInt(String(input.val() || 0), 10);

    if (itemQuantity !== displayedQuantity) {
      // dispatch an event to update the JS select box UI but not triggering a full change event
      // (which would call /update_items again)
      input.val(itemQuantity);
      input.trigger(UPDATE_DISPLAY_EVENT);
    }

    if (cartItem.ff) {
      const ffInput = itemRow.find(`#item-${itemID}-freeform-base-price`);
      ffInput.val(cartItem.ff);
    }

    const seatDisplay = itemRow.find('.select-tickets-seat-descriptions');
    const qids = itemRow.find(`#item-${itemID}-qids`);

    // everything after is for assigned seating so we can bail early here if this is a GA item
    if (!cartItem.s) {
      // we may have removed all seats so we reset everything here
      seatDisplay.text('');
      qids.val('');
      return;
    }

    qids.val(Object.keys(cartItem.s).join(','));

    if (cartItem && cartItem.d) {
      seatDisplay.html(cartItem.d);
    } else if (seatDisplay.length) {
      seatDisplay.text('');
    }
  });
};

function cartDataToGA4(data: CartData): Gtag.EventParams {
  const value = parseFloat(data.grandTotal.replace('$', ''));

  const items: Gtag.Item[] = Object.keys(data.cartItems).map((itemID) => {
    const item = data.cartItems[itemID];

    return { item_id: itemID, item_name: item.t, quantity: item.q };
  });

  return {
    currency: data.currency,
    transaction_id: data.orderID.toString(),
    value: value,
    items: items,
  };
}

const recordFacebookPixelEvent = function (data: CartData) {
  if (!window.fbq) return;
  if (data.totalItems <= 0) return;

  try {
    const content_ids = Object.keys(data.cartItems);
    const value = parseFloat(data.grandTotal.replace('$', ''));

    window.fbq('track', 'AddToCart', {
      content_ids: content_ids,
      content_type: 'product',
      value: value,
      currency: data.currency,
    });
  } catch (error) {
    console.error(error);
  }
};

const onCartUpdate = function (data: CartData) {
  console.warn('stripe debug: onCartUpdate');
  renderCartStatus(data);
  updateCheckoutLinks(data);
  updateItemRows(data);
  highlightCheckoutLink();
  recordFacebookPixelEvent(data);

  if (data.totalItems > 0) {
    trackAnalyticsV4Event('add_to_cart', cartDataToGA4(data));
  }

  // maybe show the added_too_many notice
  if (data.errors) {
    alert(data.errors.join('\n\n'));
  }
};

const onItemSelect = function () {
  navCheckoutLink.removeClass('updated');
  if (needToShowPreAddWarning()) {
    if (!confirmPreAddWarning()) return false;
  }

  const xhr = updateItemsXHR();
  const itemRow = $(this).closest('.select-tickets-row');

  itemRow.removeClass('in-cart').addClass('adding-to-cart');

  itemRow.find('.proceed-img').addClass('highlight');

  xhr.then(onCartUpdate).fail(function () {
    let message;
    // TODO: i18n
    // let message = errorMessages[xhr.status];
    if (message == null) {
      message = 'Something went wrong';
    }
    onCartUpdate({ currency: 'CAD', errors: [message] });
  });
};

const getCartStatus = (): Promise<void> => {
  if (!cartPath) {
    throw new Error('Missing cartPath');
  }

  return fetchCartStatus(cartPath)
    .then((data) => {
      renderCartStatus(data);
      updateCheckoutLinks(data);
      updateItemRows(data);
    })
    .catch((error) => {
      console.error(error);
    });
};

const handleVisibilityChange = function () {
  if (document.hidden) return;
  getCartStatus();
  trackEvent('cart', 'status-after-visibility-change');
};

const warnAssignedSeatChange = function (
  event: JQuery.ChangeEvent<Document, any, HTMLSelectElement, HTMLSelectElement>,
) {
  event.preventDefault();
  const $qidsInput = $(event.currentTarget)
    .closest('.select-tickets-row')
    .find('.checkout-qids-input') as JQuery<HTMLInputElement>;

  const qidsInput = $qidsInput[0];

  if (!qidsInput) {
    return;
  }

  if (confirm(window.Tickit_Checkout_i18n.changing_qids)) {
    return (qidsInput.disabled = true);
  }
};

const initCartUIElements = function () {
  itemRows = $('.select-tickets-row');
  proceedBtns = itemRows.find('.proceed-img');
  checkoutFromEventBtn = $('.checkout-from-event-btn');

  quantityLabel = $('.nav-item-checkout .cart-quantity-count');
  selectInputs = $('.add-items select.quantity-input');
  freeformInputs = $('.add-items input.freeform-base-price');
  navCheckoutLink = $('.nav-item-checkout');

  if (!selectInputs.length) return;

  redemptionCode = $('.add-items[data-redemption-code]').attr('data-redemption-code');
  updateCartEndpoint = selectInputs.closest('form').attr('action');
  selectInputs.on('change', onItemSelect);

  freeformInputs.on('change', (event) => {
    const row = $(event.currentTarget).closest('.select-tickets-row');
    const quantityInput = row.find('select.quantity-input');
    // only update the cart if the quantity is greater than 0
    const quantity = parseInt(quantityInput.val() as string, 10);
    if (quantity > 0) onItemSelect();
  });
};

export default function ($body: JQuery<HTMLBodyElement>, storeURL: string): Promise<void> {
  body = $body;
  cartPath = body.attr('data-cart-path');
  cartCountdown(body, storeURL);
  initCartUIElements();
  formElement = $body.find('.add-items')[0] as HTMLFormElement;
  // we return the promise to make testing easier
  const initialPromise = getCartStatus();

  $(document).on(
    'change',
    '.quantity-input',
    (event: JQuery.ChangeEvent<Document, any, HTMLSelectElement, HTMLSelectElement>) =>
      warnAssignedSeatChange(event),
  );

  document.addEventListener('visibilitychange', handleVisibilityChange, false);
  document.addEventListener(
    CART_UPDATED_EVENT_TYPE,
    function (event: CustomEvent<CartData>) {
      onCartUpdate(event.detail);
    },
    false,
  );

  return initialPromise;
}
