import { trackSocial } from './analytics';
import getCSRFToken from '../common/getCSRFToken';
export default function () {
  if (!$('.social-actions').length) return;

  const btn = $('.btn-optin[href]');
  if (!btn.length) return;

  btn.click((event) => {
    event.preventDefault();
    btn.attr('disabled', 'disabled').text('...');
    const xhr = $.ajax({
      type: 'POST',
      url: btn.attr('href'),
      dataType: 'json',
      headers: {
        'X-CSRF-Token': getCSRFToken(),
      },
      xhrFields: {
        withCredentials: true,
      },
    });

    xhr.done(function (data) {
      btn.attr('disabled', null).text(data.message);
      trackSocial('email', 'optin', 'email-list');
    });
  });
}
