import { reportWarning } from '../common/errorReporting';
import { trackEvent } from './analytics';
import { onResize, OPEN_CLASS } from './itemGroups';
import { scrollToElement } from './utils';

export const ANALYTICS_CATEGORY = 'scroll-direct-link-slug';

// detect direct links to tickets in URLs and scroll down to the selected ticket
export default function () {
  try {
    const hash = window.location.hash;

    // check for Item#direct_link_slug prefix
    if (hash.indexOf('t-') !== 1) return;

    const target = $(hash);
    if (!target.length) return;

    const group = target.closest('.select-tickets-group');
    if (group.length && !group.hasClass('open')) {
      // expand the element's group if it is collapse
      group.addClass(OPEN_CLASS);
      onResize();
    }

    scrollToElement(target);
    trackEvent(ANALYTICS_CATEGORY, target[0].id);
  } catch (error) {
    reportWarning(error);
  }
}
