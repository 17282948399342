import $ from 'jquery';
// cartDonation
let doc = $(document);

const setFreeformPriceInput = (value) => {
  const input = doc.find('#add-donation-form #freeform-price-input');
  if (value != 'other') {
    input.val(value);
  } else {
    input.focus();
  }
};

const priceChangeHandler = (event) => {
  const value = event.target.value;
  setFreeformPriceInput(value);

  const options = $(event.target).closest('article').find('.pgm-radio-option');
  options.removeClass('selected');

  const selectedOption = $(event.target).closest('.pgm-radio-option');
  selectedOption.addClass('selected');
};

export default function () {
  doc.ready(() => {
    doc.on('change', '#add-donation-form .donation-presets input[type=radio]', priceChangeHandler);
  });
}
