import React from 'react';

export enum SelectionState {
  MissingDate = 1,
  MissingTimeslot,
  Ready,
}

// TODO: I18n
const NoTicketsNotice: React.FC<{ selectionState: SelectionState }> = (props) => {
  let msg: string;
  switch (props.selectionState) {
    case SelectionState.MissingDate: {
      return null;
      // msg = 'Please select a date.';
      // break;
    }

    case SelectionState.MissingTimeslot: {
      msg = 'Please select a time.';
      break;
    }

    default: {
      msg = 'There are no matching tickets available.';
    }
  }

  return (
    <div className="item select-tickets-row select-occurrences-no-match">
      <p className="no-tickets-notice">{msg}</p>
    </div>
  );
};

export default NoTicketsNotice;
