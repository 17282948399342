import { logOrderActivity, trackSocial } from './analytics';
import getCSRFToken from '../common/getCSRFToken';
let appID, rsvpPrompt, pagePrompt, rsvpMessage;
let localeData = {};
let facebookJS = `https://connect.facebook.net/en/sdk.js`;

const loadLocale = function () {
  localeData = JSON.parse($('#social-i18n').attr('data-locale'));
  let facebookLocale = localeData.locale;

  if (facebookLocale.indexOf('fr') === 0) {
    facebookLocale = 'fr_CA';
  }
  facebookJS = `https://connect.facebook.net/${facebookLocale}/sdk.js`;
};

const initFacebook = function () {
  window.FB.init({
    autoLogAppEvents: true,
    appId: appID,
    version: 'v2.10',
  });

  if (window.FB.AppEvents != null) {
    window.FB.AppEvents.logPageView();
  }

  if (window.FB.Event != null) {
    window.FB.Event.subscribe('edge.create', function (url) {
      logOrderActivity('like-page', { url });
      trackSocial('facebook', 'like', url);
    });
  }

  rsvpPrompt.click(handleRSVP);
};

export default function () {
  const socialActions = $('.social-actions');
  if (!socialActions.length) return;

  appID = socialActions.attr('data-facebook-app-id');

  loadLocale();

  const likePrompt = $('.facebook-page-prompt');
  rsvpPrompt = $('.facebook-rsvp-prompt');
  pagePrompt = $('.facebook-page-prompt');
  rsvpMessage = $('.complete-actions-title');

  // only load FB when needed
  if (rsvpPrompt.length === 0 && pagePrompt.length === 0 && likePrompt.length === 0) {
    return;
  }

  $.ajaxSetup({ cache: true });
  $.getScript(facebookJS, function () {
    if (typeof window.FB === 'undefined' || window.FB === null) {
      console.warn('Unable to load the Facebook API');
      return;
    }

    try {
      initFacebook();
    } catch (error) {
      console.error(error);
    }
  });
}

const handleRSVP = (event) => {
  event.preventDefault();
  setRSVPMessage('');
  rsvpPrompt.attr('disabled', 'disabled');
  window.FB.login(
    (response) => {
      rsvpPrompt.removeAttr('disabled');
      if (response.status === 'connected') {
        postRSVPs();
        recordFacebookProfile(response.authResponse.userID);
        // TODO: ifresponse.status == 'not_authorized'
      } else {
        setRSVPMessage(localeData.facebook_rsvp_no_auth);
      }
    },
    { scope: 'public_profile,email,rsvp_event', return_scopes: true }
  );
};

const postRSVPs = () => {
  const eventIDs = rsvpPrompt.attr('data-event-ids').split(',');

  eventIDs.forEach((eventID) => {
    window.FB.api(`/${eventID}/attending`, 'POST', function (response) {
      if (!response.success) {
        console.error(response.error);
      }
    });
  });
  setRSVPMessage(localeData.facebook_rsvp_success);
  logOrderActivity('rsvp');
  trackSocial('facebook', 'rsvp', eventIDs.join(','));
};

const setRSVPMessage = (text) => {
  rsvpMessage.text(text);
};

const recordFacebookProfile = function (uid) {
  $.ajax({
    type: 'POST',
    url: rsvpPrompt.attr('data-profile-url'),
    data: {
      facebook_user_id: uid,
    },
    dataType: 'json',
    headers: {
      'X-CSRF-Token': getCSRFToken(),
    },
    xhrFields: {
      withCredentials: true,
    },
  });
};
