export default function (form) {
  const shippingForm = form.find('.checkout-shipping-address-form');
  const sameAddressToggle = form.find('#checkout_same_shipping_address');
  const toggleShippingAddress = () => {
    if (sameAddressToggle.prop('checked')) {
      shippingForm.hide();
    } else {
      shippingForm.show();
    }
  };

  toggleShippingAddress();
  sameAddressToggle.on('change', () => toggleShippingAddress());
}
