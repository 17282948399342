import Siema from 'siema';

/**
 * Setup the "related events" carousel on the frontend
 */
const initCarousel = function (container) {
  const carouselContainer = container.querySelector('.related-store-events-container');
  const carousel = new Siema({
    selector: container.querySelector('.related-store-events'),
    onInit() {
      return this.selector.classList.add('show');
    },
  });

  const updateUI = function () {
    const hasPrev = carousel.currentSlide > 0;
    const hasNext = carousel.currentSlide < carousel.innerElements.length - 1;
    carouselContainer.classList.toggle('has-previous', hasPrev);
    carouselContainer.classList.toggle('has-next', hasNext);
  };

  const prev = container.querySelector('.prev-button');
  const next = container.querySelector('.next-button');

  prev.addEventListener('click', function (event) {
    event.preventDefault();
    carousel.prev(1, updateUI);
  });

  next.addEventListener('click', function (event) {
    event.preventDefault();
    carousel.next(1, updateUI);
  });

  updateUI();
};

const init = () => {
  const containers = document.querySelectorAll('.related-store-event-block');
  containers.forEach(initCarousel);
};

export default function () {
  init();
  $(document).on('tickit.update-ui', () => {
    init();
  });
}
