const copyFromGuestOne = (container: JQuery<HTMLDivElement>) => {
  let guestData = { name: undefined, email: undefined, phone: undefined };
  container.find('.ticket-contacts-row').each(function (this, index) {
    const row = $(this);

    if (index === 0) {
      guestData = {
        name: row.find<HTMLInputElement>('input.ticket-contact-name').val(),
        email: row.find<HTMLInputElement>('input.ticket-contact-email').val(),
        phone: row.find<HTMLInputElement>('input.ticket-contact-phone').val(),
      };
      return;
    }

    row.find('input.ticket-contact-name').val(guestData.name);
    row.find('input.ticket-contact-email').val(guestData.email);
    row.find('input.ticket-contact-phone').val(guestData.phone);
  });
};

const init = (container: JQuery<HTMLDivElement>) => {
  const toggleBtn = container.find('.btn-copy-contact-info') as JQuery<HTMLButtonElement>;
  toggleBtn.on('click', function (event) {
    event.preventDefault();
    copyFromGuestOne(container);
  });
};

export default function () {
  const container = $('.ticket-contacts-page') as JQuery<HTMLDivElement>;
  if (container.length < 1) return;
  init(container);
}
