import React from 'react';

interface Props {
  requiredQuantity: number;
  selectedQuantity: number;
}

export default function BuilderProgress(props: Props) {
  const { requiredQuantity, selectedQuantity } = props;

  const badges = Array(requiredQuantity)
    .fill(1)
    .map((_, index) => {
      if (selectedQuantity >= index + 1) {
        return (
          <span key={index} className="progress-badge progress-success">
            <i className="ti ti-checkmark"></i>
          </span>
        );
      } else {
        return (
          <span key={index} className="progress-badge progress-pending">
            <span>{index + 1}</span>
          </span>
        );
      }
    });

  return (
    <div className="builder-progress">
      {badges.map((badge, index) => (
        <React.Fragment key={index}>
          {badge}
          {index < badges.length - 1 && <span className="spacer" key={`spacer-${index}`} />}
        </React.Fragment>
      ))}
    </div>
  );
}
