import React from 'react';
import PropTypes from 'prop-types';

const CurrentSeat = props => {
  const removeSeat = event => {
    event.preventDefault();
    props.handleRemoveSeat(props.qid);
  };

  const removeButton = () => {
    if (!props.allowSelection) return false;
    return (
      <a className="remove" onClick={removeSeat}>
        <span className="ti ti-close" />
      </a>
    );
  };

  const klass = props.highlighted ? 'seatmap-cart-seat highlighted' : 'seatmap-cart-seat';

  return (
    <div className={klass}>
      <div className="title">
        <div className="description">
          {props.d}
          <div className="subtitle">{props.itemTitle}</div>
        </div>
        <div className="price">{props.price}</div>
        {removeButton()}
      </div>
    </div>
  );
};

CurrentSeat.propTypes = {
  handleRemoveSeat: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired,
  d: PropTypes.string.isRequired,
  s: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  allowSelection: PropTypes.bool,
  highlighted: PropTypes.bool,
  qid: PropTypes.string.isRequired
};

export default CurrentSeat;
