import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import Waiting from '../Waiting';
import { stripeElementsAppearance } from '../StripeElementsTheme';
import fetchSetupIntent from './fetchSetupIntent';
import SetupForm from './SetupForm';

interface Props {
  stripePromise: Promise<Stripe>;
  setupIntentPath: string;
  confirmSetupIntentPath: string;
  openSans: string;
}

const UpdateStripeRemoteProfileForm: React.FC<Props> = (props) => {
  const [setupIntentSecret, setSetupIntentSecret] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (setupIntentSecret == undefined) {
      fetchSetupIntent(props.setupIntentPath)
        .then(({ client_secret }) => {
          setSetupIntentSecret(client_secret);
        })
        .catch((err) => {
          console.error('fetchSetupIntent error', err.toString());
        });
    }
  }, [setupIntentSecret, props.setupIntentPath]);

  const options: StripeElementsOptions = {
    fonts: [{ family: 'Open Sans', src: props.openSans, weight: '300' }],
    clientSecret: setupIntentSecret,
    appearance: stripeElementsAppearance(window.Tickit_Checkout_i18n.primary_color),
  };

  if (!setupIntentSecret) {
    return <Waiting message={'...'} />;
  }

  return (
    <Elements stripe={props.stripePromise} options={options}>
      <SetupForm confirmSetupIntentPath={props.confirmSetupIntentPath} />
    </Elements>
  );
};

export default UpdateStripeRemoteProfileForm;
