export const OPEN_CLASS = 'expanded';

let form = null;

const bindBassCoastYearSelect = function () {
  const wrapper = form.find('.bcf-cancel-prefs-year-selection');
  if (wrapper.length < 1) return;
  const ids = ['bcf-first-choice', 'bcf-second-choice', 'bcf-third-choice'];
  console.log('bindBassCoastYearSelect!!');

  const yearSelectors = form.find('select.bcf-year');
  console.log('yearSelectors', yearSelectors.length);

  function update() {
    const alreadySelected = [];
    const additionalData = {};

    ids.forEach(function (id) {
      const control = form.find(`#${id}`);

      control.find('option').each(function () {
        const option = $(this);
        const optionValue = option.attr('value');
        if (alreadySelected.includes(optionValue)) {
          option.attr('disabled', 'disabled');
        } else {
          option.removeAttr('disabled');
        }
      });

      const selected = control.val();

      if (selected && selected.length > 0) {
        alreadySelected.push(selected);
      }

      additionalData[id] = selected;
    });
    const json = JSON.stringify(additionalData);
    console.log('done', alreadySelected, json);

    $('#bcf-additional-data').val(json);
  }

  yearSelectors.change(update);

  $('.bcf-year-reset').click(function (event) {
    event.preventDefault();
    ids.forEach(function (id) {
      $(`#${id}`).val(undefined);
    });
    update();
  });

  update();
};

const bind = function () {
  // const amountInput = form.find('.cancellation-partial-donation-amount');

  function update(checkedInput) {
    form.find('.cancellation-preference-group').each(function () {
      const group = $(this);

      const prefs = group.find('.cancellation-preference-details');
      if (checkedInput && checkedInput[0] && $.contains(group[0], checkedInput[0])) {
        prefs.removeAttr('hidden');
        prefs.find('input').removeAttr('disabled');
      } else {
        prefs.attr('hidden', 'hidden');
        prefs.find('input').attr('disabled', 'disabled');
      }
    });
  }

  form.find('input[name="cancellation_preference"]').change(function (event) {
    event.preventDefault();
    const checkedInput = form.find('input[name="cancellation_preference"]:checked');
    update(checkedInput);
  });

  const checkedInput = form.find('input[name="cancellation_preference"]:checked');
  update(checkedInput);
};

export default function init() {
  form = $('.order-cancellation-preference-form');
  if (!form.length) return;
  bind();
  bindBassCoastYearSelect();
}
