import PropTypes from 'prop-types';
import React from 'react';

const LargeCloseButton = (props) => {
  return (
    <div className="nav-item-checkout checkout-with-status-btn seatmap-checkout">
      <a className="btn-store" href="#" onClick={props.handleClose}>
        <span className="nav-item-link-text">Close</span>
      </a>
    </div>
  );
};

LargeCloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default LargeCloseButton;
