import React, { MouseEventHandler, useState } from 'react';
import { CartData, LoadingState } from '../../frontend/types';
import { postData } from './api';
import { groupBy } from 'lodash';
import BuilderProgress from './BuilderProgress';
import ComboComponent from './ComboComponent';
import QuantitySelector from '../QuantitySelector';
import { MultipackJsonResponse } from './types';
import classNames from 'classnames';
export interface Props
  extends Pick<
    MultipackJsonResponse,
    'multipackID' | 'qty' | 'max' | 'required' | 'comboOptions' | 'componentOptions'
  > {
  titleLabel: string; // I18n orders.select_multipack_components
  addToCartLabel: string;
  cancelLabel: string;
  cart?: CartData;
  updateEndpoint: string;
  handleClose: () => void;
}

const MultipackBuilder: React.FC<Props> = (props) => {
  const { componentOptions, required, multipackID } = props;
  const [lineItemQuantity, setLineItemQuantity] = useState<number>(props.qty < 1 ? 1 : props.qty);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Ready);
  const [selectedComponents, setSelectedComponents] = useState<Array<string>>(
    props.comboOptions.map((option) => option.item_id.toString()),
  );
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  const availableQuantities = {};
  const eventTitles = {};
  componentOptions.forEach((item) => {
    const { eventID, itemID, max } = item;
    availableQuantities[itemID] = max;
    if (!eventTitles[eventID]) {
      eventTitles[eventID] = item.eventTitle;
    }
  });

  const enoughOfItem = (itemID: string, quantity: number) => {
    const quantityAvailable = availableQuantities[itemID];

    const enough = quantityAvailable && quantityAvailable >= quantity;
    return enough;
  };

  const reachedCapacity = selectedComponents.length >= required;

  const adjustLineItemQuantity = (newQuantity: number) => {
    if (newQuantity > props.max) {
      throw new Error('new quantity is greater than max');
    }

    const newSelectedComponents = selectedComponents.filter((itemID) =>
      enoughOfItem(itemID, newQuantity),
    );

    setLineItemQuantity(newQuantity);
    setSelectedComponents(newSelectedComponents);
  };

  const handleComponentClick = (itemID: string) => {
    if (selectedComponents.includes(itemID)) {
      setSelectedComponents(selectedComponents.filter((id) => id !== itemID));
      return;
    }

    if (reachedCapacity) {
      console.warn('too many components selected');
      return;
    }

    if (!enoughOfItem(itemID, lineItemQuantity)) {
      console.warn('not enough of item', { itemID, lineItemQuantity });
      return;
    }

    setSelectedComponents([...selectedComponents, itemID]);
  };

  const handleSubmit: MouseEventHandler = async (event) => {
    event.preventDefault();
    setLoadingState(LoadingState.Posting);

    try {
      const result = await postData(
        props.updateEndpoint,
        multipackID,
        lineItemQuantity,
        selectedComponents,
      );

      if (result.errors) {
        setErrorMessages(result.errors);
        setLoadingState(LoadingState.Error);
        return;
      }

      setLoadingState(LoadingState.Posted);
      props.handleClose();
    } catch (error) {
      console.error(error);
      setErrorMessages([error.message]);
      setLoadingState(LoadingState.Error);
    }
  };

  const optionsByEvent = groupBy(componentOptions, 'eventTitle');

  const renderErrors = () => {
    if (errorMessages.length < 1) return null;

    return (
      <div className="validation-error-notice">
        {errorMessages.map((message) => (
          <p key={message}>{message}</p>
        ))}
      </div>
    );
  };

  const rendered = Object.keys(optionsByEvent).map((eventTitle) => {
    const options = optionsByEvent[eventTitle].map((item) => {
      const { itemID, itemTitle, max } = item;
      return (
        <ComboComponent
          key={itemID}
          itemID={itemID}
          title={itemTitle}
          quantityAvailable={max}
          soldout={max < lineItemQuantity}
          disabled={reachedCapacity && !selectedComponents.includes(itemID)}
          selected={selectedComponents.includes(itemID)}
          onClick={handleComponentClick}
        />
      );
    });

    return (
      <div className="multipack-builder-event" key={eventTitle}>
        <h3 className="event-title">{eventTitle}</h3>
        {options}
      </div>
    );
  });

  return (
    <>
      <header className="multipack-builder-header">
        <h3 className="pgm-header-title">{props.titleLabel}</h3>
        <div className="multipack-builder-quantity-selector">
          <QuantitySelector
            currentQuantity={lineItemQuantity}
            maxQuantity={props.max}
            setLineItemQuantity={adjustLineItemQuantity}
          />
        </div>
      </header>
      <article>
        {renderErrors()}
        {rendered}
      </article>
      <footer>
        <BuilderProgress requiredQuantity={required} selectedQuantity={selectedComponents.length} />
        <div className="pgm-action-links">
          <button
            className={classNames({
              'btn-sm': true,
              'pgm-primary-action-link': true,
              'btn-primary': reachedCapacity,
              // "btn-primary": reachedCapacity,
            })}
            disabled={!reachedCapacity}
            onClick={handleSubmit}
          >
            {props.addToCartLabel}
          </button>
          <button className="btn-sm btn-outline pgm-close-trigger" onClick={props.handleClose}>
            {props.cancelLabel}
          </button>
        </div>
      </footer>
    </>
  );
};

export default MultipackBuilder;
