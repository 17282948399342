import getCSRFToken from '../common/getCSRFToken';

export class PaymentIntentAlreadyCaptured extends Error {
  payment_intent_id: string;
  checkout_job_id: string;
  redirect_url: string;

  constructor(payment_intent_id: string, checkout_job_id: string, redirect_url: string) {
    super('PaymentIntentAlreadyCaptured');
    this.name = 'PaymentIntentAlreadyCaptured';
    this.payment_intent_id = payment_intent_id;
    this.checkout_job_id = checkout_job_id;
    this.redirect_url = redirect_url;
  }
}

export class ZeroDollarPaymentIntent extends Error {
  constructor() {
    super('ZeroDollarPaymentIntent');
    this.name = 'ZeroDollarPaymentIntent';
  }
}

export class NoOrderForPaymentIntent extends Error {
  constructor() {
    super('NoOrderForPaymentIntent');
    this.name = 'NoOrderForPaymentIntent';
  }
}

export interface StoredPaymentSource {
  paysrc_id: string;
  client_secret: string;
  amount_cents: number;
  currency: string;
  country: string;
  checkout_job_id: string;
}

function fetchPaymentIntent(paymentIntentPath: string): Promise<StoredPaymentSource> {
  const xhr: JQuery.jqXHR<StoredPaymentSource> = $.ajax({
    type: 'POST',
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
    },
    url: paymentIntentPath,
    headers: {
      Accept: 'application/json; charset=utf-8',
    },
    xhrFields: {
      withCredentials: true,
    },
  });

  return new Promise<StoredPaymentSource>((resolve, reject) => {
    xhr.then(function (data) {
      resolve(data);
    });

    xhr.fail(function (response) {
      const json = response.responseJSON;
      const reason = json?.payment_response_code;

      if (reason === 'already_captured') {
        reject(
          new PaymentIntentAlreadyCaptured(json.paysrc_id, json.checkout_job_id, json.redirect_url)
        );
      } else if (reason === 'zero_dollar_pi') {
        reject(new ZeroDollarPaymentIntent());
      } else if (response.status === 404) {
        // empty cart, likely the user is re-opening a tab after the order completed or expired
        reject(new NoOrderForPaymentIntent());
      } else {
        const message = reason || 'FetchPaymentIntentError';
        reject(new Error(message));
      }
    });
  });
}

export default fetchPaymentIntent;
