import { post } from '@rails/request.js';
import { reportError } from '../common/errorReporting';

const init = ($form: JQuery<HTMLFormElement>) => {
  const container = document.getElementById('protecht-container');
  const endpoint = container?.getAttribute('data-endpoint');

  if (!container || !window.Tickit_Protecht_Quote_Data) {
    console.debug('protecht data not found');
    return;
  }

  if (!endpoint) {
    console.error('protecht endpoint not found');
    return;
  }

  const existingQuoteToken: string = $form.attr('data-protecht-quote-token') || '';
  let newQuoteToken = '';
  console.log('checkoutProtecht.init', { existingQuoteToken });

  const updateCart = async (quote_token) => {
    const response = await post(endpoint, {
      body: { quote_token },
      contentType: 'application/json',
      responseKind: 'json',
    });

    console.log('got response', response);

    if (!response.ok) {
      console.error('Failed to add refund_protection to cart');
      reportError(new Error('Failed to add refund_protection to cart'));
      return;
    }

    console.log('Updated cart refund_protection', {
      redirected: response.redirected,
      url: response.url,
    });

    if (existingQuoteToken !== quote_token) {
      console.log('Reloading since quote_token changed', { quote_token, existingQuoteToken });
      // refresh
      window.location.reload();
    } else {
      console.log('Ignoring reload since quote_token did not change', {
        quote_token,
        existingQuoteToken,
      });
      newQuoteToken = quote_token;
    }

    if (response.redirected && response.url) {
      if (newQuoteToken !== quote_token) {
        console.log('Redirecting to', response.url);
        newQuoteToken = quote_token;
        // window.history.pushState({}, '', response.url);
        window.location.replace(response.url);
      } else {
        console.log('Ignoring redirect to', response.url);
        newQuoteToken = quote_token;
      }
      //
    }
  };

  const handlers = {
    loadedCb: function () {
      const isComplete = window.tg.isFormComplete(); // returns a boolean
      const quote = window.tg.get('quote'); // returns the protection cost
      const persistedSelection = window.tg.get('persistedSelection');

      console.log('protecht loadedCb', { isComplete, quote, persistedSelection });
      // TODO:
      // 1. by checking isComplete, conditionally show UI elements. If false:
      // --> a) alert the user to provide an opt-in or opt-out selection
      // 2. verify the protection cost displayed in the widget matches the protection cost stored in the quote variable
    },
    optInCb: function () {
      const isComplete = window.tg.isFormComplete(); // returns a boolean
      const isProtected = window.tg.isProtected(); // returns a boolean
      const quoteToken = window.tg.get('token'); // returns a JWT with quote data to pass through to your back-end
      const persistedSelection = window.tg.get('persistedSelection');

      console.log('protecht optInCb', { isComplete, isProtected, quoteToken, persistedSelection });
      updateCart(quoteToken);

      // TODO:
      // 1. by checking isProtected, conditionally update the order total and **add** the protection cost in your cart's UI. If true:
      // --> a) send the quoteToken to update the cart total on your application's back end (add the protection cost)
      // --> b) update your cart's UI to reflect the **addtion** of the protection cost in the cart total
      // --> c) update your cart's UI to reflect the **addition** of the protection cost as a cart line item (optional)
      // 2. by checking isComplete, conditionally show UI elements. If true:
      // --> a) do not alert the user to provide an opt-in or opt-out selection
    },
    optOutCb: function () {
      const isComplete = window.tg.isFormComplete(); // returns a boolean
      const isProtected = window.tg.isProtected(); // returns a boolean
      const quoteToken = window.tg.get('token'); // returns a JWT with quote data to pass through to your back-end
      const persistedSelection = window.tg.get('persistedSelection');

      console.log('protecht optOutCb', { isComplete, isProtected, quoteToken, persistedSelection });
      updateCart('');

      // TODO:
      // 1. by checking isProtected, conditionally update the order total and **remove** the protection cost in your cart's UI. If false:
      // --> a) send the quoteToken to update the cart total on your application's back end (remove the protection cost)
      // --> b) update your cart's UI to reflect the **removal** of the protection cost in the cart total
      // --> c) update your cart's UI to reflect the **removal** of the protection cost as a cart line item (optional)
      // 2. by checking isComplete, conditionally show UI elements. If true:
      // --> a) do not alert the user to provide an opt-in or opt-out selection
    },
    updatedCb: function () {
      console.log('protecht updatedCb');

      const quote = window.tg.get('quote'); // returns the protection cost
      const quoteToken = window.tg.get('token'); // returns a JWT with quote data to pass through to your back-end
      const persistedSelection = window.tg.get('persistedSelection');
      console.log('protecht updatedCb', { quote, quoteToken, persistedSelection });
      updateCart(quoteToken);

      // TODO:
      // 1. update the order total and **update** the protection cost in your cart's UI:
      // --> a) send the quoteToken to update the cart total on your application's back end (update the protection cost)
      // --> b) update your cart's UI to reflect the **change** of the protection cost in the cart total
      // --> c) update your cart's UI to reflect the **change** of the protection cost as a cart line item (optional)
    },
    onErrorCb: function (message) {
      const isComplete = window.tg.isFormComplete(); // returns a boolean
      const isProtected = window.tg.isProtected(); // returns a boolean

      console.log('protecht onErrorCb', { isComplete, isProtected });
      reportError(new Error(message));

      // TODO:
      // 1. hide UI elements related to the widget
      // --> a) do not alert the user to provide an opt-in or opt-out selection
      // --> b) do not display any text or style elements associated with the widget
      // --> c) if your checkout process is a multi-step form, programmatically proceed to the next step (optional)
      // --> d) log the error with your monitoring service (optional)
    },
  };
  const params = { ...window.Tickit_Protecht_Quote_Data, ...handlers, persist: true };

  console.debug('protecht data found', params);
  window.tg('configure', params);

  // protecht:
  // client_id: 0000636c-0000-0000-0000-247a9bf1194a
  // client_name: Tickit.ca Test
  // client_public_key: pk_sandbox_7e0d3a236e91328b6df13912f4a9291fd9f57e11
  // client_secret_key: sk_sandbox_ccf67b8daf97eca6eed3d1915991fa71cc483c06
  // affiliate_id: 00006166-0000-0000-0000-233359eda7be
  // affiliate_name: Tickit.ca
  // affiliate_public_key: pk_sandbox_381b6d5c1a5f1f554fc11ff4c20116c46e9c2813
  // affiliate_secret_key: sk_sandbox_b08001af73e99c5aa3ef71a90f24bd16b98cfbc5

  // tg('configure', {
  // apiKey: 'pk_sandbox_0bd6c2ecce6e28c0ef89e2d02effce26304ea948', // from create-client api call
  // apiKey: 'pk_sandbox_7e0d3a236e91328b6df13912f4a9291fd9f57e11', // client from slack
  //   apiKey: 'pk_sandbox_381b6d5c1a5f1f554fc11ff4c20116c46e9c2813', // affiliate from slack
  //   currency: 'CAD',
  //   items: [
  //     {
  //       cost: '100.00',
  //     },
  //   ],
  // });
};

export default function ($form: JQuery<HTMLFormElement>) {
  $form;
  init($form);
  $(document).on('tickit.update-ui', () => {
    init($form);
  });
}
