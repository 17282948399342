export function addSVGClass(seatNode: SVGElement, klass: string): void {
  const existingClass = seatNode.className.baseVal || '';
  seatNode.className.baseVal = `${existingClass} ${klass}`;
}

export function removeSVGClass(seatNode: SVGElement, klass: string): void {
  const existingClass = seatNode.className.baseVal || '';
  seatNode.className.baseVal = `${existingClass.replace(klass, ' ')}`;
}

export function createSVGElement(tagName: string, className?: string): SVGElement {
  const element = document.createElementNS('http://www.w3.org/2000/svg', tagName) as SVGElement;
  if (className) {
    element.className.baseVal = className;
  }
  return element;
}
