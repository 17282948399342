/*
  This is the checkout flow for Stripe, our preferred gateway.  We use Stripe's PaymentElement
  to abstract away as much of the payment flow as possible.
 */

import { loadStripe } from '@stripe/stripe-js';
import jQuery from 'jquery';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { reportError, reportWarning } from '../common/errorReporting';
import getCSRFToken from '../common/getCSRFToken';
import CheckoutErrorBoundary from '../components/CheckoutErrorBoundary';
import StripeCheckout from '../components/StripeCheckout';
import { trackEvent } from './analytics';
import { CheckoutError, createErrorFromJSON, createErrorFromXHR } from './checkoutErrors';
import { renderErrors } from './checkoutValidationErrors';
import { ICardData, ICheckoutResult, IPretokenizedCardData } from './types';

// export jQuery to global window so its available to jquery-ujs
window.jQuery = jQuery;

export const SUBMITTING_CLASS = 'submitting-tokenized-form';
const USE_HISTORY_API = window.Modernizr.history;

let body: JQuery = undefined;
let form: JQuery<HTMLFormElement> = undefined;
let initialTitle: string = undefined;
const initialURL = document.location.pathname;
let checkoutURL: string = undefined;
let checkoutParams: ICardData = {};
let paymentPlanID: number = undefined;

const checkCheckoutJob = (data: ICheckoutResult, resolve, reject) => {
  switch (data.status) {
    case 'pending': {
      setTimeout(() => {
        jQuery
          .ajax({
            dataType: 'json',
            url: data.status_url,
            xhrFields: {
              withCredentials: true,
            },
            headers: {
              Accept: 'application/json; charset=utf-8',
              'X-CSRF-Token': getCSRFToken(),
            },
          })
          .then((data: ICheckoutResult) => {
            return checkCheckoutJob(data, resolve, reject);
          });
      }, 350);
      break;
    }
    case 'failed': {
      trackEvent('checkout', 'checkout-job-failed');

      try {
        if (data && data.errors) {
          console.log('trying to create error from JSON', data);
          const created = createErrorFromJSON(data);
          console.log('created error', created);
          reject(created);
          return;
        }
      } catch (e) {
        console.error(e);
      }

      reject(new CheckoutError(window.Tickit_Checkout_i18n.something_went_wrong));

      break;
    }
    case 'succeeded': {
      trackEvent('checkout', 'checkout-job-succeeded');
      resolve(data);
      break;
    }
  }
};

export const submitCheckout = (checkoutData, checkoutJobID: string | null = null) => {
  return new Promise<ICheckoutResult>((resolve, reject) => {
    jQuery
      .ajax({
        type: 'POST',
        url: checkoutURL,
        xhrFields: {
          withCredentials: true,
        },
        headers: {
          Accept: 'application/json; charset=utf-8',
          'X-CSRF-Token': getCSRFToken(),
        },
        data: { checkout: checkoutData, checkout_job_id: checkoutJobID },
        dataType: 'json',
      })
      .then(function (data: ICheckoutResult) {
        if (data.status_url && USE_HISTORY_API) {
          try {
            window.history.replaceState(
              {},
              window.Tickit_Checkout_i18n.still_processing_title,
              data.status_url
            );
          } catch (error) {
            reportWarning('checkoutWithStripe.submitCheckout caught replaceState error', error);
            console.warn(error);
          }
        }

        return checkCheckoutJob(data, resolve, reject);
      })
      .fail(function (xhr: JQuery.jqXHR) {
        trackEvent('checkout', 'checkout-error');
        reject(createErrorFromXHR(xhr));
      });
  });
};

const restoreForm = function () {
  body.removeClass(SUBMITTING_CLASS);
  window.TickitCheckoutSubmitting = false;
  if (USE_HISTORY_API) {
    try {
      history.replaceState({}, initialTitle, initialURL);
    } catch (error) {
      console.error(error);
    }
  }
};

const onPaymentIntentSucceeded = async function (
  paymentIntentID: string,
  checkoutJobID: string,
  formData: ICardData
) {
  window.TickitCheckoutSubmitting = true;
  body.addClass(SUBMITTING_CLASS);
  try {
    const usingPaymentPlan = !isNaN(paymentPlanID) && paymentPlanID > 0;
    const termsAccepted = true; // we do not allow submitting the form unless the terms are accepted

    const checkoutData: IPretokenizedCardData = {
      name: formData.name,
      email: formData.email,
      optin: formData.optin,
      zip: formData.zip,
      terms_accepted: termsAccepted,
      payment_intent_id: paymentIntentID,
      payment_plan_id: usingPaymentPlan ? paymentPlanID : undefined,
    };

    console.log('onPaymentIntentSucceeded', checkoutData, { checkoutJobID });

    const checkoutResult: ICheckoutResult = await submitCheckout(checkoutData, checkoutJobID);
    window.parent.location.href = checkoutResult.redirect_url;
  } catch (checkoutError) {
    console.error(checkoutError);
    reportError(checkoutError, 'onPaymentIntentSucceeded caught');
    renderErrors(checkoutError);
    restoreForm();
  }
};

export default function ($form: JQuery<HTMLFormElement>) {
  form = $form;

  // some stores use Stripe but do not have their `publishable_key` configured so we use the regular form
  // stores with `publishable_key` configured add the class `stripejs` and use this flow with PaymentIntents
  if (!form.hasClass('stripejs')) return;
  if (form.hasClass('remote-payment-profile-form')) return;

  // $0 orders are handled by the 'checkoutWithBackgroundProcessing' scripts
  if (form.hasClass('no-payment-required')) return;

  body = jQuery('body');
  checkoutURL = form.attr('data-checkout-path');

  checkoutParams = JSON.parse(form.attr('data-checkout-params') || '{}');
  paymentPlanID = checkoutParams.payment_plan_id;
  initialTitle = jQuery('title').text();

  const publishableKey = form.attr('data-stripe-publishable-key');
  const stripeAccountID = form.attr('data-stripe-account-id'); // account ID of the CONNECTED (ticket seller) stripe account
  const payingPath = form.attr('data-paying-path');
  const paymentIntentPath = form.attr('data-payment-intent-path');
  const validatePath = form.attr('data-validate-path');
  const openSans = form.attr('data-open-sans-font');
  const optinMode = form.attr('data-optin-mode') as
    | 'automatic_optin'
    | 'default_optin'
    | 'default_optout';
  const stripePromise = loadStripe(publishableKey, {
    stripeAccount: stripeAccountID,
    apiVersion: '2023-10-16',
  });
  const root = createRoot(form[0]);
  root.render(
    <React.StrictMode>
      <CheckoutErrorBoundary paymentIntentPath={paymentIntentPath}>
        <StripeCheckout
          paymentIntentPath={paymentIntentPath}
          payingPath={payingPath}
          validatePath={validatePath}
          stripePromise={stripePromise}
          paymentPlanID={paymentPlanID}
          onPaymentIntentSucceeded={onPaymentIntentSucceeded}
          openSans={openSans}
          checkoutParams={checkoutParams}
          optinMode={optinMode}
        />
      </CheckoutErrorBoundary>
    </React.StrictMode>
  );
  return;
}
